<script>
	import moment from "moment";

	import Base from "@/utils/base";

	export default {
		props: ["is_show"],
		data() {
			return {
				base: null,
				arr_otp: ["", "", "", "", "", ""],
				submitted: false,
				from_system: false,
				is_please_wait: false,
				countdown_timer: null,
			};
		},
		watch: {
			is_show(val) {
				if (val) this.send_otp();
			},
			arr_otp: {
				handler(newValue) {
					if(this.from_system){
						this.from_system = false
						return
					}
					
					var arr = JSON.parse(JSON.stringify(newValue))
					var counter = 0
					var counter1 = 0
					var index = -1
					while(counter < arr.length){
						if(arr[counter].length > 1){
							var len = arr[counter].length
							var word = arr[counter]
							for(let y = 0; y < len; y++){
								arr[counter + y] = word[y]
							}
							
							counter = -1
						}
						else if(arr[counter] == '' && index < 0)
							index = counter
						else if(arr[counter] != '')
							counter1++
							
						counter++
					}
					this.from_system = true
					this.arr_otp = arr
					
					console.log(counter1)
					if(index >= 0)
						this.$refs.inputOtp[index].focus()
					else if(counter1 == arr.length){
						this.$refs.inputOtp[counter - 1].blur();
						this.submit();
					}
						
					
					
				},
				deep: true
			}
		},
		async mounted() {
			this.base = new Base();
			
			
		},
		methods: {
			async manage_countdown() {
				
				var available_moment = moment().add(1, "m");
				var context = this;

				var interval = setInterval(() => {
					if (!available_moment.isSame(moment(), "second"))
						context.countdown_timer = moment.duration(available_moment.clone().subtract(moment()));
					else {
						context.countdown_timer = null;
						clearInterval(interval);
					}
				}, 1000);
			},
			async send_otp() {
				var user_data = await localStorage.getItem("user");
				if (user_data != null) user_data = JSON.parse(user_data);
				// window.$('#please_wait_modal').modal('show')
				var response = await this.base.request(this.base.url_api + "/auth/otp", "post", {
					id: user_data.id,
				});
				// setTimeout(() => {
				// 	window.$('#please_wait_modal').modal('hide')
				// }, 500)

				if (response != null) {
					if (response.status === "success") {
						this.manage_countdown();
					} else this.base.show_error(response.message);
				} else this.base.show_error(this.$t("server_error"));
			},
			async submit() {
				var flag = false;
				for (let otp of this.arr_otp) {
					if (otp == "") {
						flag = true;
						break;
					}
				}

				if (flag) this.base.show_error("OTP is Empty");
				else {
					var otp_str = "";
					for (let otp of this.arr_otp) otp_str += otp;

					var user_data = await localStorage.getItem("user");
					if (user_data != null) user_data = JSON.parse(user_data);

					window.$("#please_wait_modal").modal("show");
					var response = await this.base.request(this.base.url_api + "/auth/otp/confirm", "post", {
						otp_code: otp_str,
						id: user_data.id,
					});
					setTimeout(() => {
						window.$("#please_wait_modal").modal("hide");
					}, 500);

					if (response != null) {
						if (response.status == "success") {
							await localStorage.setItem("token", response.token);
							window.localStorage.setItem(
								"token_expired",
								moment().add(1, "d").format("YYYY-MM-DD HH:mm:ss")
							);
							await localStorage.setItem("user", JSON.stringify(response.user));

							window.location.reload();
						} else this.base.show_error(response.message);
						
					} else this.base.show_error("Server Error");
				}
			},
			onOTPKeyup(index, e) {
				if (e.keyCode == 8 && index > 0) this.$refs.inputOtp[index - 1].focus();
				// else if (e.keyCode != 8) {
				// 	console.log(index)
				// 	if (index >= this.arr_otp.length){
				// 		this.$refs.inputOtp[index].blur();
				// 		this.submit();
				// 	}
				// }
			},
			onBackClicked() {
				this.base.back_page_redirect();
			},
		},
	};
</script>

<template>
	<div class="row bg-white p-5 justify-content-center position-relative">
		<button
			type="button"
			class="close position-absolute w-auto"
			data-dismiss="modal"
			aria-label="Close"
			style="right: 0; top: 0; opacity: 1; background-color: var(--thm-base);">
			<img src="@/assets/login_close.png" />
		</button>
		<!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-center"> -->
		<!-- <div class="col-auto d-flex align-items-center justify-content-center">
			<img src="@/assets/logo_new.png" style="max-width: 240px;">
		</div> -->

		<div class="col-auto col-lg d-flex align-items-center justify-content-center position-relative">
			<!-- <a href="/" class="position-absolute" style="top: 1rem; right: 0rem;">
			</a> -->
			<!-- <div style="max-width: 25.6rem;"> -->
			<div style="">
				<p class="text-primary login-title">OTP</p>
				<p class="m-0 login-detail">Enter your OTP</p>

				<div
					class="d-grid mt-3"
					style="display: grid; grid-template-columns: repeat(6, 3rem); gap: 0.5rem">
					<div v-for="(otp, index) in arr_otp" :key="index" class="">
						<input
							type="text"
							v-model="arr_otp[index]"
							@keyup="onOTPKeyup(index, $event)"
							ref="inputOtp"
							class="form-control text-center" />
					</div>
				</div>

				<div class="mt-3">
					<p class="m-0 " v-if="countdown_timer != null">
						Resend OTP in
						{{
							(countdown_timer.minutes() < 10
								? "0" + countdown_timer.minutes()
								: countdown_timer.minutes()) +
							":" +
							(countdown_timer.seconds() < 10
								? "0" + countdown_timer.seconds()
								: countdown_timer.seconds())
						}}
					</p>
					<a href="#!" class="m-0" v-else @click="send_otp">Resend OTP</a>
					<button class="btn btn-primary  w-100" @click="submit">Submit</button>
					<!-- <p class="m-0 text-white login-forget mt-3">By clicking on Sign up, you agree to our Terms of service and Privacy policy.</p> -->
				</div>
			</div>
		</div>
	</div>
</template>
