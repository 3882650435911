<template>
	
	<div class="card container my-5">
		<div class="card-body">

			<div class="row">
				<div class="col-12 col-md-auto">
					<div class="form-group">
						<label for="name">Image</label>
						<custom-image-picker
							:image_data1="image_data"
							@onResponse="onImageResponse"/>
					</div>
				</div>
				<div class="col-12 col-md">
					<div class="form-group">
						<label for="email">Email</label>
						<input type="email" v-model="email" class="form-control" id="email" placeholder="Enter email"/>
					</div>
					<div class="form-group">
						<label for="name">Name</label>
						<input type="text" v-model="name" class="form-control" id="name" placeholder="Enter Name"/>
					</div>
				</div>
				<div class="col-12 col-md">
					<div class="form-group">
						<label for="phone">Phone</label>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">+62</span>
							</div>
							<input type="text" v-model="phone" class="form-control" id="phone" placeholder="Enter Phone"/>
						</div>
					</div>
					
					<div class="form-group">
						<label for="address">Address</label>
						<textarea v-model="address" class="form-control" id="address" placeholder="Enter Address"></textarea>
					</div>
				</div>
				<div class="col-12 d-flex justify-content-end">
					<div class="form-group m-0">
						<button class="btn btn-primary w-100" @click="onSubmit">Submit</button>
					</div>
				</div>
			</div>

			
			
			
		</div>
	</div>
	
</template>

<script>
import Base from '@/utils/base';
import CustomImagePicker from '@/layout/custom_image_picker';
import NoImage from '@/assets/no_image.png';

export default {
	components: {
		CustomImagePicker,
	},
	props: ['isFinished', ],
	data(){
		return{
			base: null,
			email: '',
			name: '',
			phone: '',
			address: '',
			user_data: {},
			image_data: {},
		}
	},
	async mounted(){
		this.base = new Base()
		this.base.save_current_page('Change Profile', true)
		
		this.get_auth()
	},
	methods:{
		onImageResponse(image_data){
			this.image_data = image_data
		},
		async get_auth(){
			var response = await this.base.request(this.base.url_api + "/auth/profile")
		
			if(response != null){
				if(response.status === "success"){
					this.user_data = response.data
					this.name = response.data.name
					this.email = response.data.email
					this.address = response.data.address
					this.phone = response.data.phone != null ? response.data.phone.substring(3) : ''
					this.image_data = {
						image_display: response.data.file_name != null ? this.base.host + '/media/user?file_name=' + response.data.file_name : NoImage,
						image_data: null,
					}
				}
				else
					this.base.show_error(response.message)
			}
			else
				this.base.show_error(this.$t('server_error'))
		},
		async onSubmit(){
			if(this.email == '')
				this.base.show_error('Email is Empty')
			else if(this.name == '')
				this.base.show_error('Name is Empty')
			else if(this.phone == '')
				this.base.show_error('Phone is Empty')
			else if(this.address == '')
				this.base.show_error('Address is Empty')
			else{
				window.$('#please_wait_modal').modal('show')
				
				var data = {
					email: this.email,
					name: this.name,
					phone: '+62' + this.phone,
					address: this.address,
				}
				if(this.image_data.data_image != null)
					data.image = {
						file: this.image_data.data_image,
					}
				
				var response = await this.base.request(this.base.url_api + '/auth/change-profile', 'post', data)
				
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)
				
				if (response != null) {
					if (response.status == 'success') {
						window.location.href = '/'
					}
					else
						this.base.show_error(response.message)
				}
				else
					this.base.show_error("Server Error")
			}
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
