<template>
	
	<div v-if="data.id != null">
		
		<property-image :data="data"/>
		<property-info :data="data"/>
		<property-detail :data="data" :user_data="user_data"/>
		<modal-offer :data="data"/>
		
	</div>
	
</template>

<script>
import moment from 'moment'

import Base from '@/utils/base';

import PropertyImage from '@/pages/property/component/property_image'
import PropertyDetail from '@/pages/property/component/property_detail'
import PropertyInfo from '@/pages/property/component/property_info'
import ModalOffer from '@/pages/property/component/modal_offer'

import NoImage from '@/assets/no_image.png'

export default {
	components: {
		PropertyImage,
		PropertyDetail,
		PropertyInfo,
		ModalOffer,
	},
	props: [],
	data(){
		return{
			base: null,
			data: {},
			user_data: {},
		}
	},
	watch:{
		data(val){
			if(val.image != null && val.image.length > 0){
				setTimeout(() => {
					this.base.addScript(this.base.arrCustomJS)
				}, 500)
			}
		},
	},
	async created(){
		this.base = new Base()
		this.base.save_current_page('Detail Property')
		
		var token = await localStorage.getItem('token')
		
		if(token != null)
			await this.get_auth()
		this.get_data()
	},
	methods:{
		async get_auth(){
			var response = await this.base.request(this.base.url_api + "/auth/profile")
		
			if(response != null){
				if(response.status === "success"){
					this.user_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				this.base.show_error(this.$t('server_error'))
		},
		async get_data(){
			var response = await this.base.request(this.base.url_api + "/property" + (this.user_data.id != null ? '/auth' : '') + "?id=" + this.$route.query.id)
		
			if(response != null){
				if(response.status === "success"){
					if(response.data.is_publish == 0){
						this.base.show_error('Property not published! Redirecting...')
						window.location.href = "/property/list"
					}
					
					response.data.url_image = response.data.file_name != null ? this.base.host + '/media/property?file_name=' + response.data.file_name : NoImage
					for(let offer of response.data.offer){
						if(offer.user != null)
							offer.user.url_image = offer.user.file_name != null ? this.base.host + '/media/user?file_name=' + offer.user.file_name : NoImage
					}
					if(response.data.own_offer != null){
						response.data.own_offer.created_at_moment = moment(response.data.own_offer.created_at)
					}
					
					this.data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.listing_details_top_product_list_box:before{
	display: none;
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
