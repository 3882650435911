<script>
	// import moment from 'moment'

	import Base from "@/utils/base";

	export default {
		data() {
			return {
				base: null,
				phone: "",
				is_please_wait: false,
			};
		},
		watch: {
			phone(val) {
				this.phone = this.base.phone_validation(val);
			},
		},
		async created() {
			this.base = new Base();
		},
		methods: {
			async submit() {
				if (this.phone == "") this.base.show_error("Phone is Empty");
				else {
					this.is_please_wait = true;
					var response = await this.base.request(this.base.url_api + "/auth/login", "post", {
						email: "+62" + this.phone,
					});
					this.is_please_wait = false;

					if (response != null) {
						if (response.status == "success") {
							await localStorage.setItem("user", JSON.stringify(response.user));

							this.$emit("changeModal", true);
							window.$("#login-modal").modal("hide");
							window.$("#otp-modal").modal("show");
						} else this.base.show_error(response.message);
					} else this.base.show_error("Server Error");
				}
			},
			onRegisterClicked() {
				window.location.href = "/auth/register";
			},
			onBackClicked() {
				this.base.back_page_redirect();
			},
		},
	};
</script>

<template>
	<div class="row bg-white rounded-lg p-5 justify-content-center position-relative">
		<button
			type="button"
			class="close position-absolute w-auto"
			data-dismiss="modal"
			aria-label="Close"
			style="right: 0; top: 0; opacity: 1; background-color: var(--thm-base);">
			<img src="@/assets/login_close.png" />
		</button>
		<!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-center"> -->
		<!-- <div class="col-auto d-flex align-items-center justify-content-center">
			<img src="@/assets/logo_new.png" style="max-width: 240px;">
		</div> -->

		<div class="col-auto col-lg d-flex align-items-center justify-content-center position-relative">
			<!-- <a href="/" class="position-absolute" style="top: 1rem; right: 0rem;">
			</a> -->
			<!-- <div style="max-width: 25.6rem;"> -->
			<div style="">
				<p class="text-primary login-title">Login</p>
				<!-- <p class="m-0 text-primary login-detail">Welcome Back</p> -->

				<div class="form-group mt-4">
					<p class="mb-2 login-detail">Phone</p>
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text" id="basic-addon1">+62</span>
						</div>
						<input type="text" v-model="phone" class="form-control" />
					</div>
				</div>

				<div class="mt-4">
					<button class="btn btn-primary text-white w-100" @click="submit">
						{{ $t("login") }}
					</button>
					<!-- <p class="m-0 text-white login-forget mt-3">By clicking on Sign up, you agree to our Terms of service and Privacy policy.</p> -->
				</div>

				<div class="d-flex align-items-center my-3">
					<div class="login-hr"></div>
					<p class="m-0 text-primary login-detail mx-3">or</p>
					<div class="login-hr"></div>
				</div>

				<div>
					<!-- <a href="/auth/register" class="btn btn-white border-primary text-white login-reset-password w-100">{{$t('create_account') }}</a> -->
					<a
						href="#"
						class="btn btn-primary text-white login-reset-password w-100"
						data-dismiss="modal"
						data-toggle="modal"
						data-target="#register-modal"
						>Register</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	.custom-title {
		color: $primary;
	}

	html,
	body,
	body .fullscreen {
		height: 100%;
	}

	.bg-login {
		background-color: $primary;
	}

	.login-hr {
		width: 100%;
		height: 1px;
		border: 1px solid $primary;
	}

	.login-title {
		font-size: 2rem;
	}

	.login-detail {
	}

	.login-forget {
	}

	.login-reset-password {
	}

	.trust-card {
		background-color: $gray9;
		border: none;
	}

	.title-section {
		font-size: 2rem;
	}

	.content-section {
		color: $gray6;
	}

	.team-role {
		color: $black1;
	}

	.team-name {
		color: $black1;
	}

	.about-us-title1-enter-active,
	.about-us-title1-leave-active,
	.about-us-title2-enter-active,
	.about-us-title2-leave-active,
	.about-us-title3-enter-active,
	.about-us-title3-leave-active {
		transition: all 2s;
	}

	.about-us-title1-leave-to,
	.about-us-title1-enter,
	.about-us-title2-leave-to,
	.about-us-title2-enter,
	.about-us-title3-leave-to,
	.about-us-title3-enter {
		transform: translateX(-10rem);
		opacity: 0;
	}

	.about-us-content1-enter-active,
	.about-us-content1-leave-active,
	.about-us-content2-enter-active,
	.about-us-content2-leave-active,
	.about-us-content3-enter-active,
	.about-us-content3-leave-active {
		transition: all 2s;
	}

	.about-us-content1-leave-to,
	.about-us-content1-enter,
	.about-us-content2-leave-to,
	.about-us-content2-enter,
	.about-us-content3-leave-to,
	.about-us-content3-enter {
		transform: translateX(10rem);
		opacity: 0;
	}

	.about-us-image1-enter-active,
	.about-us-image1-leave-active,
	.about-us-image2-enter-active,
	.about-us-image2-leave-active,
	.about-us-image3-enter-active,
	.about-us-image3-leave-active {
		transition: all 2s;
	}

	.about-us-image1-leave-to,
	.about-us-image1-enter,
	.about-us-image2-leave-to,
	.about-us-image2-enter,
	.about-us-image3-leave-to,
	.about-us-image3-enter {
		// margin-left: 10rem !important;
		opacity: 0;
	}

	#about-us-content img {
		width: 100%;
		border-radius: 1rem;
	}

	.trust-list > p:nth-child(1) {
		min-height: 100px;
		text-align: center;
	}

	.trust-list > p:nth-child(2) {
		text-align: justify;
		text-align-last: center;
	}
</style>
