<template>
  
	<section class="banner-one">
			<Transition name="banner-loading1">
				<div class="banner-bg-slide " :data-options="JSON.stringify(options)" v-show="!is_loading"></div>
			</Transition>
			<Transition name="banner-loading">
				<div class="position-absolute w-100 h-100 " style="top: 0; background-size: 100% 100%;" :style="{backgroundImage: 'url(' + require('@/assets/no_image.png') + ')'}" v-show="is_loading"></div>
			</Transition>
		
			<div class="container">
					<div class="content-box" style="">
							<div class="top-title">
									<h2>Find Your Future <br> Dream Home</h2>
							</div>
	
							<div class="product-tab-box tabs-box">
									<ul class="tab-btns tab-buttons clearfix list-unstyled">
										<li data-tab="#desc" class="tab-btn active-btn"><span>Tawar</span></li>
									</ul>
									<div class="tabs-content">
										
											<div class="tab active-tab" id="desc">
													<form class="banner_one_search_form" action="/property/list">
															<div class="banner_one_search_form_input_box d-flex flex-column flex-md-row bg-white align-items-stretch align-items-md-center justify-content-between pr-4" style="border-radius: 0 .25rem .25rem .25rem;">
																<div class="d-flex align-items-stretch align-items-md-center w-100">
																	<div class="banner_one_search_icon" >
																		<a href="#"><span class="icon-magnifying-glass"></span></a>
																	</div>
																	<input type="text" class="flex-fill" name="search" placeholder="Cari properti untuk ditawar" v-model="search">
																</div>
																<button type="button" class="thm-btn banner_one_search_btn m-0 rounded" style="" @click="onSearch">Cari</button>

															</div>
													</form>
											</div>
											
									</div>
							</div>
							<!-- <div class="banner_one_bottom_icon_text">
									<div class="banner_one_bottom_icon">
											<span class="icon-building"></span>
									</div>
									<div class="banner_one_bottom_text">
											<p>Smart Real Estate Services All our the World</p>
									</div>
							</div> -->
					</div>
			</div>
	</section>
	
</template>

<script>
import Base from '@/utils/base';

// import Image1 from '@/assets/images/main-slider/slider-1-1.jpg';
// import Image2 from '@/assets/images/main-slider/slider-1-2.jpg';
import NoImage from '@/assets/no_image.png'

export default {
  components: {
    // 'ArticleItem': ArticleItem,
  },
  props: ['articleTitle1Flag', 'articleTitle2Flag', ],
  data(){
    return{
      base: null,
			search: '',
			options: {},
			arr: [],
			is_loading: true,
    }
  },
  watch: {
    isLoading(val){
      this.$emit("onLoading", val, 2)
    },
  },
  created(){
    this.base = new Base()
		
		this.get_data()
  },
  methods:{
		onSearch(){
			window.location.href = "/property/list?search=" + this.search
		},
		async get_data(){
			var response = await this.base.request(this.base.url_api + "/banner/all")
		
			if(response != null){
				if(response.status == "success"){
					for(let data of response.data){
						data.url_image = data.file_name != null ? this.base.host + '/media/banner?file_name=' + data.file_name : NoImage
						data.src = data.file_name != null ? this.base.host + '/media/banner?file_name=' + data.file_name : NoImage
					}
					this.arr = response.data
					this.options = { 
						"delay": 5000, 
						"slides": response.data,
						"transition": "fade", 
						"timer": false, 
						"align": "top", 
						"animation": [ 
							"kenburnsUp", 
							"kenburnsDown", 
							"kenburnsLeft", 
							"kenburnsRight" 
						] 
					}
					
					var context = this
					setTimeout(() => {
						context.is_loading = false
					}, 1000)
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-right: .5rem;
  }
  .article-second{
    padding-left: .5rem;
  }
}
@media only screen and (min-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-left: .5rem;
  }
  .article-second{
    padding: 0 .5rem;
  }
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
  transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
  transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
  transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}

.banner-loading-enter-active, .banner-loading-leave-active{
	transition: all 2s;
}
.banner-loading-leave-to, .banner-loading-enter {
	opacity: 0;
}
.banner-loading1-enter-active, .banner-loading1-leave-active{
	transition: all 2s;
}
.banner-loading1-leave-to, .banner-loading1-enter {
	opacity: 1;
}
.active-btn{
	border: 2px solid #FF2D55;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
</style>
