import axios from 'axios'

export default class Base{
  host = "https://property-legoas-api.quantumtri.com"
  // host = "https://legoas-api.quantumtri.com"
  // host = ""
  url_api = this.host + "/api"
  version = ""
  locale_string = "id-ID"
  local_currency = "Rp."
  local_area_phone = "+62"
  wait_time = 1500
  app_version = '0.2.0054'

  host2 = "https://lelang.legoas.co.id:8443"
  url_api2 = this.host2 + "/api/portal"
  auth = "Basic ODUwMWRjNjRiOTEzNDcyZmE0Mjc0MTE5MTEzYjdjNGQ6TFNTLWRjYTU0ZDQ0ODFjZDRlYTU4NDAwYzk4NDM3N2EzMzk1"

	arrBaseJS = [
		window.location.origin + "/assets/js/jquery.min.js",
		window.location.origin + "/assets/js/bootstrap.bundle.min.js",
		window.location.origin + "/assets/js/owl.carousel.min.js",
		window.location.origin + "/assets/js/waypoints.min.js",
		window.location.origin + "/assets/js/jquery.counterup.min.js",
		window.location.origin + "/assets/js/TweenMax.min.js",
		window.location.origin + "/assets/js/wow.js",
		window.location.origin + "/assets/js/jquery.magnific-popup.min.js",
		window.location.origin + "/assets/js/jquery.ajaxchimp.min.js",
		window.location.origin + "/assets/js/swiper.min.js",
		window.location.origin + "/assets/js/typed-2.0.11.js",
		window.location.origin + "/assets/js/vegas.min.js",
		window.location.origin + "/assets/js/jquery.validate.min.js",
		window.location.origin + "/assets/js/bootstrap-select.min.js",
		window.location.origin + "/assets/js/countdown.min.js",
		window.location.origin + "/assets/js/jquery.mCustomScrollbar.concat.min.js",
		window.location.origin + "/assets/js/bootstrap-datepicker.min.js",
		window.location.origin + "/assets/js/nouislider.min.js",
		window.location.origin + "/assets/js/isotope.js",
		window.location.origin + "/assets/js/appear.js",
		window.location.origin + "/assets/js/jarallax.js",
		window.location.origin + "/assets/js/jquery-ui.js",


	];
	arrCustomJS = [



		window.location.origin + "/assets/js/theme.js",

	]

  constructor(){
		if(window.location.origin == "https://ditawaraja.id"){
			this.host = "https://api.ditawaraja.id"
			this.url_api = this.host + "/api"
		}
		else if(window.location.origin == "https://property-legoas-web.quantumtri.com"){
			this.host = "https://property-legoas-api.quantumtri.com"
			this.url_api = this.host + "/api"
		}
		else if(window.location.origin == "http://localhost:8000"){
			this.host = "https://api.ditawaraja.id"
			// this.host = "https://property-legoas-api.quantumtri.com"
			this.url_api = this.host + "/api"
		}
  }

  async request(url, method = 'get', data = {}, with_modal = true, onUploadProgress = () => {}){
    try{
      axios.defaults.headers.common['Accept'] = 'application/json'

      var context = this
      var header = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Type": "web",
      }
      var token = await window.localStorage.getItem('token')
      if(url.includes(this.host2))
        header['Authorization'] = this.auth
      else if(token != null && token != '')
        header['Authorization'] = token

      var response
      if(method === 'get'){
        for(let x in data)
          url += (url.includes('?') ? '&' : '?') + x + "=" + JSON.stringify(data[x])

        response = await axios.get(url, {
          headers: header,
        })
        .catch(function (error) {
          if (error.response.status != 200) {
						if(error.response.status == 401){
							window.localStorage.removeItem('token')
							window.localStorage.removeItem('token_expired')
						}
            context.show_error(error.response.data)
          }
        })
      }
      else if(method === 'post')
        response = await axios.post(url, data, {
          headers: header,
          onUploadProgress
        })
        .catch(function (error) {
          if (error.response.status != 200) {
						if(error.response.status == 401){
							window.localStorage.removeItem('token')
							window.localStorage.removeItem('token_expired')
						}
            context.show_error(error.response.data)
          }
        })
      else if(method === 'put')
        response = await axios.put(url, data, {
          headers: header,
          onUploadProgress
        })
        .catch(function (error) {
          if (error.response.status != 200) {
						if(error.response.status == 401){
							window.localStorage.removeItem('token')
							window.localStorage.removeItem('token_expired')
						}
            context.show_error(error.response.data)
          }
        })
      else if(method === 'delete')
        response = await axios.delete(url, {
          headers: header,
          data: data,
        })
        .catch(function (error) {
          if (error.response.status != 200) {
						if(error.response.status == 401){
							window.localStorage.removeItem('token')
							window.localStorage.removeItem('token_expired')
						}
            context.show_error(error.response.data)
          }
        })

      if(with_modal){
        setTimeout(() => {
        }, 500)
      }



      return response.data
    } catch(error){
      // console.log(error.response.data)
      return null
    }
  }

	reloadTheme(){
	// $(window).on("load", function () {

			// swiper slider
			var latest_properties_img_carousel = null

			const swiperElm = document.querySelectorAll(".thm-swiper__slider");

			swiperElm.forEach(function (swiperelm) {
					const swiperOptions = JSON.parse(swiperelm.dataset.swiperOptions);
					let thmSwiperSlider = new Swiper(swiperelm, swiperOptions);

					$('.thm-swiper__slider-pause-hover').on('mouseenter', function (e) {
							thmSwiperSlider.autoplay.stop();
					});
					$('.thm-swiper__slider-pause-hover').on('mouseleave', function (e) {
							thmSwiperSlider.autoplay.start();
					});
			});

			if ($("#testimonials-one__thumb").length) {
					let testimonialsThumb = new Swiper("#testimonials-one__thumb", {
							slidesPerView: 3,
							spaceBetween: 0,
							speed: 1400,
							watchSlidesVisibility: true,
							watchSlidesProgress: true,
							loop: true,
							autoplay: {
									delay: 5000
							}
					});

					let testimonialsCarousel = new Swiper("#testimonials-one__carousel", {
							observer: true,
							observeParents: true,
							speed: 1400,
							mousewheel: true,
							slidesPerView: 1,
							autoplay: {
									delay: 5000
							},
							thumbs: {
									swiper: testimonialsThumb
							}
					});
			}


			if ($(".thm__owl-carousel").length) {
					$(".thm__owl-carousel").each(function () {
							var Self = $(this);
							var carouselOptions = Self.data("options");
							var carouselPrevSelector = Self.data("carousel-prev-btn");
							var carouselNextSelector = Self.data("carousel-next-btn");
							var thmCarousel = Self.owlCarousel(carouselOptions);
							if (carouselPrevSelector !== undefined) {
									$(carouselPrevSelector).on("click", function () {
											thmCarousel.trigger("prev.owl.carousel");
											return false;
									});
							}
							if (carouselNextSelector !== undefined) {
									$(carouselNextSelector).on("click", function () {
											thmCarousel.trigger("next.owl.carousel");
											return false;
									});
							}
					});
			}


			// Latest Properties Img Carousel
			if ($(".latest_properties_img_carousel").length) {
					$(".latest_properties_img_carousel").owlCarousel({
							loop: true,
							margin: 10,
							nav: true,
							smartSpeed: 500,
							autoHeight: false,
							lazyLoad: true,
							dots: false,
							navText: [
									'<span class="icon-right-arrow left-arrow"></span>',
									'<span class="icon-right-arrow"></span>',
							],
							responsive: {
									0: {
											items: 1,
									},
									600: {
											items: 1,
									},
									800: {
											items: 1,
									},
									1024: {
											items: 1,
									},
									1200: {
											items: 1,
									},
							},
					});
			}



























			if ($(".side-menu__block-inner").length) {
					$(".side-menu__block-inner").mCustomScrollbar({
							axis: "y",
							theme: "dark",
					});
			}

			if ($(".custom-cursor__overlay").length) {
					// / cursor /
					var cursor = $(".custom-cursor__overlay .cursor"),
							follower = $(".custom-cursor__overlay .cursor-follower");

					var posX = 0,
							posY = 0;

					var mouseX = 0,
							mouseY = 0;

					TweenMax.to({}, 0.016, {
							repeat: -1,
							onRepeat: function () {
									posX += (mouseX - posX) / 9;
									posY += (mouseY - posY) / 9;

									TweenMax.set(follower, {
											css: {
													left: posX - 22,
													top: posY - 22,
											},
									});

									TweenMax.set(cursor, {
											css: {
													left: mouseX,
													top: mouseY,
											},
									});
							},
					});

					$(document).on("mousemove", function (e) {
							var scrollTop =
									window.pageYOffset || document.documentElement.scrollTop;
							mouseX = e.pageX;
							mouseY = e.pageY - scrollTop;
					});
					$("button, a").on("mouseenter", function () {
							cursor.addClass("active");
							follower.addClass("active");
					});
					$("button, a").on("mouseleave", function () {
							cursor.removeClass("active");
							follower.removeClass("active");
					});
					$(".custom-cursor__overlay").on("mouseenter", function () {
							cursor.addClass("close-cursor");
							follower.addClass("close-cursor");
					});
					$(".custom-cursor__overlay").on("mouseleave", function () {
							cursor.removeClass("close-cursor");
							follower.removeClass("close-cursor");
					});
			}

			if ($(".preloader").length) {
					$(".preloader").fadeOut();
			}

			if ($(".countdown-one__list").length) {
					$(".countdown-one__list").countdown({
							date: "June 7, 2020 15:03:25",
							render: function (date) {
									this.el.innerHTML =
											"<li> <div class='days'> <i>" +
											date.days +
											"</i> <span>Days</span> </div> </li>" +
											"<li> <div class='hours'> <i>" +
											date.hours +
											"</i> <span>Hours</span> </div> </li>" +
											"<li> <div class='minutes'> <i>" +
											date.min +
											"</i> <span>Minutes</span> </div> </li>" +
											"<li> <div class='seconds'> <i>" +
											date.sec +
											"</i> <span>Seconds</span> </div> </li>";
							},
					});
			}
	}

	async save_current_page(title, is_reset = false){
		var arr_pages = await window.localStorage.getItem('arr_pages')
		arr_pages = arr_pages != null && !is_reset ? JSON.parse(arr_pages) : []

		var counter = 0
		for(let page of arr_pages){
			if(title == page.name)
				break
			counter++
		}

		if(counter == arr_pages.length)
			arr_pages.push({
				name: title,
				url: window.location.href,
			})
		window.localStorage.setItem('arr_pages', JSON.stringify(arr_pages))
	}

	async back_page(with_redirect = true, how_many = 1){
		var arr_pages = await window.localStorage.getItem('arr_pages')
		arr_pages = JSON.parse(arr_pages)

		for(let x = 1; x <= how_many; x++)
			arr_pages.pop()
		var obj = arr_pages[arr_pages.length - 1]

		window.localStorage.setItem('arr_pages', JSON.stringify(arr_pages))

		if(with_redirect)
			window.location.href = obj.url
	}

	async back_page_redirect(){
		var arr_pages = await window.localStorage.getItem('arr_pages')
		arr_pages = JSON.parse(arr_pages)
		var url_redirect = await window.localStorage.getItem('url_redirect')

		if(url_redirect != null){
			var index = -1
			for(let x in arr_pages){
				if(arr_pages[x].url == window.location.origin + url_redirect){
					index = parseFloat(x)
					break
				}
			}
			// console.log(arr_pages.length - (index + 1))
			this.back_page(true, arr_pages.length - (index + 1))
		}
		else
			window.location.href = '/'
	}

  validate_email(email){
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  check_phone_format(data){
    return data.length > 0 && data[data.length - 1].match(/^[\d\s]+$/g) == null ? data.substring(0, data.length - 1) : data
  }

  str_to_double(data, default_value = '0'){
    var value
    if(data != '')
      value = parseFloat(data.replace(/\./g,'').replace(/,/g,'.'))
    else
      value = default_value
    return value
  }

  responsive_scroll_threshold(value, additional_margin = 0){
    if(window.screen.width < 720)
      value = value * (12 / 16) + additional_margin
    else if(window.screen.width >= 720 && window.screen.width < 1024)
      value = value * (8 / 16)
    // console.log(value)
    return value
  }

  check_start_animation(scrollY, flag, arr_factor, scroll_threshold = 0, additional_margin = 0){
    var temp = true
    for(let factor of arr_factor){
      if(!factor){
        temp = false
        break
      }
    }

    return (flag || (!flag && scrollY >= this.responsive_scroll_threshold(scroll_threshold, additional_margin))) && temp
  }

  phone_validation(data, max_length = 12, allow_slice_first_char = true){
    data = String(allow_slice_first_char ? this.str_to_double(data, '') : data)
    if(isNaN(data))
      data = '0'
    if(data.charAt(0) === '0' && allow_slice_first_char)
      data = data.slice(1)
    if(max_length > 0 && data.length > max_length)
      data = data.substring(0, max_length)
    return data
  }

  to_currency_format(data, max_number = 1000000000000, max_comma_length = 2){
    var value = data

    if(value[value.length - 1] !== ","){
      var is_include_comma = false
      var is_convert_double = true
      var index_comma = 0
      for(let x = 0; x < value.length; x++){
        if(value[x] === ","){
          is_include_comma = true
          index_comma = x
        }
        else if(is_include_comma && x == value.length - 1 && value[x] === "0")
          is_convert_double = false
      }

      if(is_include_comma){
        is_convert_double = value.length - index_comma > max_comma_length && value[value.length - 2] !== "0"
        value = value.substring(0, index_comma + 1 + max_comma_length)
      }


      if(is_convert_double){
        value = this.str_to_double(value)
        if(isNaN(value))
          value = 0
        if(value > max_number)
          value = max_number
      }
    }

    return value.toLocaleString(this.locale_string)
  }

	addScript(arr, index = 0) {
		var scriptTag = document.createElement("script");
		scriptTag.src = arr[index];
		document.getElementsByTagName("head")[0].appendChild(scriptTag);
		// console.log(scriptTag.src);
		if (arr[index + 1] != null) {
			var context = this
			setTimeout(() => {
				context.addScript(arr, index + 1);
			}, 10);
		}
		// else
		// $('#please_wait_modal').modal('hide')
	}

	addStyle(arr, index = 0) {
		var styleTag = document.createElement("style");
		styleTag.src = arr[index];
		document.getElementsByTagName("head")[0].appendChild(styleTag);
		// console.log(styleTag.src);
		if (arr[index + 1] != null) {
			var context = this
			setTimeout(() => {
				context.addStyle(arr, index + 1);
			}, 10);
		}
		// else
		// $('#please_wait_modal').modal('hide')
	}

	toDataURL(url, callback) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function() {
			var reader = new FileReader();
			reader.onloadend = function() {
				callback(reader.result);
			}
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}

	init_list_property(){
		if (window.$(".listings_page_content_carousel").length) {
				window.$(".listings_page_content_carousel").owlCarousel({
						loop: true,
						margin: 10,
						nav: true,
						smartSpeed: 500,
						autoHeight: false,
						dots: false,
						navText: [
								'<span class="icon-right-arrow left-arrow"></span>',
								'<span class="icon-right-arrow"></span>',
						],
						responsive: {
								0: {
										items: 1,
								},
								600: {
										items: 1,
								},
								800: {
										items: 1,
								},
								1024: {
										items: 1,
								},
								1200: {
										items: 1,
								},
						},
				});
		}

		if (window.$(".listings_two_page_content_carousel").length) {
				window.$(".listings_two_page_content_carousel").owlCarousel({
						loop: true,
						margin: 10,
						nav: true,
						smartSpeed: 500,
						autoHeight: false,
						dots: false,
						navText: [
								'<span class="icon-right-arrow left-arrow"></span>',
								'<span class="icon-right-arrow"></span>',
						],
						responsive: {
								0: {
										items: 1,
								},
								600: {
										items: 1,
								},
								800: {
										items: 1,
								},
								1024: {
										items: 1,
								},
								1200: {
										items: 1,
								},
						},
				});
		}
	}

  show_error(message = ""){
    // showSnackBar({
    //   message: message,
    //   textColor: '#FFF',      // message text color
    //   position: 'top',  // enum(top/bottom).
    //   confirmText: '', // button text.
    //   duration: 2000,   // (in ms), duartion for which snackbar is visible.
    // })
    alert(message)
    // this.$toasted.show(message)
    // sessionStorage.removeItem('message')
  }
}
