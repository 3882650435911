<template>
	
	<div class="card container my-5">
		<div class="card-body">
			<div class="form-group">
				<label for="old_password">Old Password</label>
				<input type="password" v-model="old_password" class="form-control" id="old_password" placeholder="Enter Old Password"/>
			</div>
			
			<div class="form-group">
				<label for="new_password">New Password</label>
				<input type="password" v-model="new_password" class="form-control" id="new_password" placeholder="Enter New Password"/>
			</div>
			
			<div class="form-group">
				<label for="confirm_password">Confirm Password</label>
				<input type="password" v-model="confirm_password" class="form-control" id="confirm_password" placeholder="Enter Confirm Password"/>
			</div>
			
			<div class="form-group">
				<button class="btn btn-primary w-100" @click="onSubmit">Submit</button>
			</div>
		</div>
	</div>
	
</template>

<script>
import Base from '@/utils/base';

export default {
	components: {
	},
	props: ['isFinished', ],
	data(){
		return{
			base: null,
			old_password: '',
			new_password: '',
			confirm_password: '',
		}
	},
	async mounted(){
		this.base = new Base()
		this.base.save_current_page('Change Password', true)
	},
	methods:{
		async onSubmit(){
			if(this.old_password == '')
				this.base.show_error('Old Password is Empty')
			else if(this.new_password == '')
				this.base.show_error('New Password is Empty')
			else if(this.confirm_password == '')
				this.base.show_error('Confirm Password is Empty')
			else if(this.confirm_password != this.new_password)
				this.base.show_error('Confirm Password is not Same with New Password')
			else{
				window.$('#please_wait_modal').modal('show')
				var response = await this.base.request(this.base.url_api + '/auth/change-password', 'post', {
					old_password: this.old_password,
					new_password: this.new_password,
				})
				
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)
				
				if (response != null) {
					if (response.status == 'success') {
						window.location.href = '/'
					}
					else
						this.base.show_error(response.message)
				}
				else
					this.base.show_error("Server Error")
			}
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
