<script>

import Base from "@/utils/base";

export default {
	data() {
		return {
			base: null,
			email: "",
			submitted: false,
			is_please_wait: false,
		};
	},
	async created() {
		this.base = new Base()
		this.base.save_current_page('Forget Password')
	},
	methods: {
		async submit() {
			if(this.email == '')
				this.base.show_error('Email is Empty')
			else if(!this.base.validate_email(this.email))
				this.base.show_error('Email is not in email format')
			else{
				this.is_please_wait = true
				var response = await this.base.request(this.base.url_api + '/auth/forget-password', 'post', {
					email: this.email,
				})
				this.is_please_wait = false
				
				if (response != null) {
					if (response.status == 'success') {
						window.location.href = '/auth/login'
					}
					else
						this.base.show_error(response.message)
				}
				else
					this.base.show_error("Server Error")
			}
		},
		onBackClicked(){
			this.base.back_page_redirect()
		},
	}
};
</script>

<template>
	<div class="container-fluid p-0 " style="min-height: 100vh;">
		<div class="row no-gutters">

			<div class="col-lg-6 position-relative">
				<div style="background-color: #002F5F;" class="w-100 h-100">
					<!-- <img src="@/assets/auth_logo.png" class="position-absolute" style="bottom: 0;"> -->
				</div>
			</div>

			<div class="col-lg-6">
				<div class="authentication-page-content p-4 d-flex align-items-center min-vh-100" style="background-color: #F5F5F5;">
					<div class="w-100">
						<div class="row justify-content-center">
							<div class="col-lg-9">
								<div>
									<div class="">
										<h4 class="font-size-18 mt-4">Forget Password</h4>
									</div>

									<div class="p-2 mt-3">
										<div class="form-group auth-form-group-custom mb-4">
											<i class="ri-mail-line auti-custom-input-icon"></i>
											<label for="email">Email</label>
											<input type="email" v-model="email" class="form-control" id="email" placeholder="Enter email"/>
										</div>

										<div class="mt-4">
											<button class="btn text-white w-md waves-effect waves-light w-100 mt-3" style="background-color: #FECA03;" @click="submit" type="submit">
												<div v-show="!is_please_wait">
													Submit
												</div>
												<div v-show="is_please_wait" class="spinner-border text-primary" role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</button>
										</div>
										
										<div class="mt-4">
											<a href="/auth/login">Back to Login</a>
										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>