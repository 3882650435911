<template>
	
	<section class="listings_details_main_image_box">
			<div class="container-full-width">
					<div class="thm__owl-carousel owl-carousel owl-theme">
							<div class="item" v-for="(image, index) in data.image" :key="index">
									<!--Listings Details Main Image Box Single-->
									<div class="listings_details_main_image_box_single">
											<div class="listings_details_main_image_box__img d-flex align-items-center" style="height: 17rem;">
													<a :href="image.url_image" class="img-popup">
														<img :src="image.url_image" style="width: '100%'; height: 'auto'; aspect-ratio: 1;" alt="">
													</a>
											</div>
									</div>
							</div>
					</div>
			</div>
	</section>
	
</template>

<script>
import Base from '@/utils/base';

export default {
	components: {
	},
	props: ['data', ],
	data(){
		return{
			base: null,
			arr: [],
			options: {
				spaceBetween: 100, 
				slidesPerView: 3, 
				autoplay: { delay: 5000 }, 
				pagination: {
					el: "#latest_properties_pagination",
					type: "bullets",
					clickable: true
				},
				navigation: {
					nextEl: ".latest_properties_next",
					prevEl: ".latest_properties_prev",
					clickable: true
				},
				breakpoints: {
					0: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					425: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					575: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					767: {
						spaceBetween: 30,
						slidesPerView: 2
					},
					991: {
						spaceBetween: 20,
						slidesPerView: 2
					},
					1289: {
						spaceBetween: 30,
						slidesPerView: 3
					},
					1440: {
						spaceBetween: 30,
						slidesPerView: 3
					},
				},
			},
		}
	},
	created(){
		this.base = new Base()
		
	},
	methods:{
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
