<template>

	<div class="container py-5 w-100">
		<div class="mb-5">
			<h4>List Offer</h4>
		</div>

		<div class="row">

			<div class="col-lg-3 d-none">
				<form>
					<div class="border rounded-lg p-3 sticky-lg-top">
						<div class="form-group">
							<label for="search">Pencarian</label>
							<input type="text" class="form-control" name="search" id="search" aria-describedby="search"
								placeholder="Masukkan kata kunci" v-model="search" />
						</div>

						<div class="form-group">
							<label for="location">Pilih Lokasi</label>
							<Select2 v-model="city" :options="arr_city" :settings="arr_setting" value="All City" id="location" />
						</div>
						
						<div class="form-group">
							<label for="property_type">Pilih Tipe Properti</label>
							<Select2 v-model="property_type" :options="arr_property_type" :settings="arr_setting_property_type" value="Semua Tipe Properti" id="property_type" />
						</div>

						<div class="form-group">
							<label>Luas Tanah</label>
							<div class="d-flex flex-row justify-content-around" style="gap: 0.5rem">
								<input type="text" class="form-control" name="size-min" id="size-min" aria-describedby="size-min"
									v-model="min_land_size" placeholder="min" />
								<input type="text" class="form-control" name="size-max" id="size-max" aria-describedby="size-max"
									v-model="max_land_size" placeholder="max" />
							</div>
						</div>

						<div class="form-group">
							<label>Luas Bangunan</label>
							<div class="d-flex flex-row justify-content-around" style="gap: 0.5rem">
								<input type="text" class="form-control" name="size-min" id="size-min" aria-describedby="size-min"
									v-model="min_building_size" placeholder="min" />
								<input type="text" class="form-control" name="size-max" id="size-max" aria-describedby="size-max"
									v-model="max_building_size" placeholder="max" />
							</div>
						</div>

						<div class="form-group">
							<label for="bedroom">Kamar Tidur</label>
							<select class="form-control" name="bedroom" id="bedroom" v-model="bedroom">
								<option>1</option>
								<option>2</option>
								<option>more</option>
							</select>
						</div>

						<div class="form-group">
							<label for="bathroom">Kamar Mandi</label>
							<select class="form-control" name="bathroom" id="bathroom" v-model="bathroom">
								<option>1</option>
								<option>2</option>
								<option>more</option>
							</select>
						</div>

						<div class="form-group">
							<label>Rentang Harga</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="my-addon">Rp</span>
								</div>
								<input type="text" class="form-control" name="price-min" id="price-min" aria-describedby="price-min"
									v-model="min_price" placeholder="min" />
							</div>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="my-addon">Rp</span>
								</div>
								<input type="text" class="form-control" name="price-max" id="price-max" aria-describedby="price-max"
									v-model="max_price" placeholder="max" />
							</div>
						</div>
						
						<div class="form-group">
							<button type="button" @click="onResetClicked"
							class="thm-btn w-100">Reset Filter</button>
						</div>
						
					</div>
				</form>
			</div>

			<div class="col-lg-12">
				<div class="row">
					<div class="col-xl-12">
						<div class="filter">
							<div class="filter_inner_content">
								
								<div class="left">
									<div class="left_text ml-0">
										<h4>{{ recordsFiltered.toLocaleString(base.locale_string) }} Data Ditemukan</h4>
									</div>
								</div>
								
								<div class="right">
									<div class="shorting">
										<select class="form-control" v-model="sort" data-width="100%">
											<option value="property_name_asc">A - Z</option>
											<option value="property_name_desc">Z - A</option>
											<option value="price_asc">Harga (Rendah ke Tinggi)</option>
											<option value="price_desc">Harga (Tinggi ke Rendah)</option>
										</select>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>

				<div class="listings_page_content">
					<div v-if="arr.length > 0">
						<div class="row">
							<div :class="{ 'col-12 col-md-6': type == 'grid', 'col-12': type == 'list', }"
								v-for="(data, index) in arr" :key="index">

								<PropertyItem
									:is_loading="is_loading"
									:data="data.property"
									:offer_data="data"
									:index="index"
									:type="type"
									list_type="offer_property"/>

							</div>
						</div>

						<div class="d-flex align-items-center justify-content-center">
							<b-pagination
								v-model="page"
								:total-rows="recordsFiltered"
								:per-page="num_data"
								aria-controls="my-table"/>
						</div>
						
					</div>

					<div v-else>
						<h4 class="text-center">No Data Found</h4>
					</div>
				</div>
			</div>

		</div>

	</div>

</template>

<script>
import Base from '@/utils/base';

import NoImage from '@/assets/no_image.png'
import PropertyItem from '@/layout/property_item'

var base = new Base()
export default {
	components: {
		PropertyItem,
	},
	props: ['isFinished',],
	data() {
		return {
			base: null,
			arr: [],
			search: '',
			is_loading: true,
			type: 'list',
			recordsFiltered: 0,
			bedroom: '0',
			bathroom: '0',
			min_land_size: "0",
			max_land_size: "0",
			min_building_size: "0",
			max_building_size: "0",
			min_price: '0',
			max_price: '0',
			page: 1,
			total_page: 3,
			num_data: 6,
			city: 'all',
			arr_city: [{
				id: 'all',
				text: 'Semua Lokasi',
			}],
			property_type: 'all',
			arr_property_type: [
				{
					id: 'all',
					text: 'Semua Tipe Properti',
				},
			],
			sort: 'property_name_asc',
			timeout: null,
			arr_setting: {
				ajax: {
					url: base.url_api + "/city",
					headers: {
						'Accept': 'application/json',
					},
					processResults: function (data) {
						var arr = [{ id: 'all', text: 'All City' }]
						for (let item of data.data) {
							item.text = item.name
							arr.push(item)
						}
			
						return {
							results: arr
						};
					},
				},
			},
			arr_setting_property_type: {
				ajax: {
					url: base.url_api + "/property/type",
					headers: {
						Accept: "application/json",
					},
					processResults: function (data) {
						var arr = [{ id: "all", text: "Semua Tipe Properti" }];
						for (let item of data.data) {
							item.text = item.name;
							arr.push(item);
						}
			
						return {
							results: arr,
						};
					},
				},
			},
		}
	},
	watch: {
		search(){
			this.onFilterChanged()
		},
		city() {
			this.get_data()
		},
		property_type() {
			this.get_data()
		},
		search() {
			this.onFilterChanged()
		},
		bedroom(val) {
			this.bedroom = this.base.to_currency_format(val)
			this.onFilterChanged()
		},
		bathroom(val) {
			this.bathroom = this.base.to_currency_format(val)
			this.onFilterChanged()
		},
		min_land_size(val) {
			if (this.base.str_to_double(val) > this.base.str_to_double(this.max_land_size))
				this.max_land_size = this.base.to_currency_format(val);
			this.min_land_size = this.base.to_currency_format(val);
		
			this.onFilterChanged();
		},
		max_land_size(val) {
			if (this.base.str_to_double(this.min_land_size) > this.base.str_to_double(val))
				this.min_land_size = this.base.to_currency_format(val);
			this.max_land_size = this.base.to_currency_format(val);
		
			this.onFilterChanged();
		},
		min_building_size(val) {
			if (this.base.str_to_double(val) > this.base.str_to_double(this.max_building_size))
				this.max_building_size = this.base.to_currency_format(val);
			this.min_building_size = this.base.to_currency_format(val);
		
			this.onFilterChanged();
		},
		max_building_size(val) {
			if (this.base.str_to_double(this.min_building_size) > this.base.str_to_double(val))
				this.min_building_size = this.base.to_currency_format(val);
			this.max_building_size = this.base.to_currency_format(val);
		
			this.onFilterChanged();
		},
		min_price(val) {
			if (this.base.str_to_double(val) > this.base.str_to_double(this.max_price))
				this.max_price = this.base.to_currency_format(val)
			this.min_price = this.base.to_currency_format(val)

			this.onFilterChanged()
		},
		max_price(val) {
			if (this.base.str_to_double(this.min_price) > this.base.str_to_double(val))
				this.min_price = this.base.to_currency_format(val)
			this.max_price = this.base.to_currency_format(val)

			this.onFilterChanged()
		},
		sort() {
			this.get_data()
		},
		arr(val) {
			if (val.length > 0) {
				setTimeout(() => {
					this.base.init_list_property()
				}, 1000)
			}
		},
		// isFinished(val){
		// 	if(val)
		// 		this.get_data()
		// },
	},
	async created() {
		this.base = new Base()
		this.base.save_current_page('List Offer', true)

		if(this.$route.query.search != null){
			this.search = this.$route.query.search
		}

		await this.get_auth()
		this.get_data()
	},
	methods: {
		onResetClicked(){
			this.search = ''
			this.city = 'all'
			this.property_type = 'all'
			this.bedroom = '0'
			this.bathroom = '0'
			this.min_land_size = '0'
			this.max_land_size = '0'
			this.min_building_size = '0'
			this.max_building_size = '0'
			this.min_price = '0'
			this.max_price = '0'
		},
		onFilterChanged() {
			if (this.timeout != null)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.get_data()
			}, 1500)
		},
		onGridClicked() {
			this.type = 'grid'
		},
		onListClicked() {
			this.type = 'list'
		},
		async get_auth() {
			var response = await this.base.request(this.base.url_api + "/auth/profile")

			if (response != null) {
				if (response.status === "success") {
					this.user_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				this.base.show_error(this.$t('server_error'))
		},
		async get_data() {
			if (this.isFinished)
				window.$('#please_wait_modal').modal('show')
			this.is_loading = true

			var sort_explode = this.sort.split('_')
			var name = ''
			for (let x in sort_explode) {
				if (x < sort_explode.length - 1)
					name += (x > 0 ? '_' : '') + sort_explode[x]
			}
			var arr_sort = [{ name: name, sort: sort_explode[sort_explode.length - 1], }]

			var response = await this.base.request(this.base.url_api + "/property/offer" + (this.user_data.id != null ? '/auth' : '/') + "?api_type=with_offer&bedroom=" + this.base.str_to_double(this.bedroom) + 
				"&bathroom=" + this.base.str_to_double(this.bathroom) + 
				"&min_price=" + this.base.str_to_double(this.min_price) + 
				"&max_price=" + this.base.str_to_double(this.max_price) + 
				"&min_land_size=" + this.base.str_to_double(this.min_land_size) +
				"&max_land_size=" + this.base.str_to_double(this.max_land_size) +
				"&min_building_size=" + this.base.str_to_double(this.min_building_size) +
				"&max_building_size=" + this.base.str_to_double(this.max_building_size) +
				"&city_id=" + this.city + 
				"&property_type_id=" + this.property_type + 
				"&search=" + this.search + 
				"&num_data=" + this.num_data +
				"&sort=" + JSON.stringify(arr_sort))

			// var response = await this.base.request(this.base.url_api + "/property" + (this.user_data.id != null ? '/auth' : '/') + "?api_type=with_offer")
			if (this.isFinished)
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)


			if (response != null) {
				if (response.status === "success") {
					var arr = []
					for (let data of response.data) {
						data.property.url_image = data.property.file_name != null ? this.base.host + '/media/property?file_name=' + data.property.file_name : NoImage
						arr.push(data)
					}
					this.arr = arr
					this.recordsFiltered = response.recordsFiltered

					var context = this
					setTimeout(() => {
						context.is_loading = false
					}, 3000)
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first {
		padding-right: .5rem;
	}

	.article-last {
		padding-right: .5rem;
	}

	.article-second {
		padding-left: .5rem;
	}
}

@media only screen and (min-width: 720px) {
	.article-first {
		padding-right: .5rem;
	}

	.article-last {
		padding-left: .5rem;
	}

	.article-second {
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
	transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
	transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
	transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
