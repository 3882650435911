<template>
	<div>
		<div id="app" v-if="!this.$route.path.match(/\/auth*/g)">
			<!-- <custom-loading :arr_factor="arr_factor"/> -->

			<div class="page-wrapper">
				<navbar @onNavbarToggle="onNavbarToggle" :user_data="user_data" />

				<router-view
					@onChangeArrFactor="(arr) => onChangeArrFactor(arr)"
					:isFinished="isFinished" />

				<footer1 :user_data="user_data" />
			</div>

			<a href="#" data-target="html" class="scroll-to-target scroll-to-top"
				><i class="fa fa-angle-up"></i
			></a>
			<side-navbar-mobile />
			<search-popup />

			<div
				id="login-modal"
				class="modal fade"
				tabindex="-1"
				role="dialog"
				aria-labelledby="login-modal-title"
				aria-hidden="true">
				<div
					class="modal-dialog modal-xl justify-content-center modal-dialog-centered"
					style="pointer-events: unset">
					<div class="model-content">
						<Login @changeModal="onChangeModal" />
					</div>
				</div>
			</div>

			<div
				id="register-modal"
				class="modal fade"
				tabindex="-1"
				role="dialog"
				aria-labelledby="register-modal-title"
				aria-hidden="true">
				<div
					class="modal-dialog modal-xl justify-content-center modal-dialog-centered"
					style="pointer-events: unset">
					<div class="model-content">
						<Register @changeModal="onChangeModal" />
					</div>
				</div>
			</div>

			<div
				id="otp-modal"
				class="modal fade"
				tabindex="-1"
				role="dialog"
				aria-labelledby="otp-modal-title"
				aria-hidden="true">
				<div
					class="modal-dialog modal-xl justify-content-center modal-dialog-centered"
					style="pointer-events: unset">
					<div class="model-content">
						<OTP :is_show="is_show_otp" />
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<router-view />
		</div>

		<please-wait-modal />
	</div>
</template>

<script>
	import Base from "@/utils/base";

	import footer1 from "@/layout/footer";
	import navbar from "@/layout/navbar";
	import SideNavbarMobile from "@/layout/side_navbar_mobile";
	import SearchPopup from "@/layout/search_popup";
	// import CustomLoading from '@/layout/custom_loading'
	import PleaseWaitModal from "@/layout/please_wait_modal.vue";
	import login from "@/pages/auth/login.vue";
	import register from "@/pages/auth/register.vue";
	import otp from "@/pages/auth/otp.vue";

	export default {
		components: {
			footer1,
			navbar,
			"side-navbar-mobile": SideNavbarMobile,
			"search-popup": SearchPopup,
			// 'custom-loading': CustomLoading,
			"please-wait-modal": PleaseWaitModal,
			Login: login,
			Register: register,
			OTP: otp,
		},
		data() {
			return {
				base: null,
				scrollY: 0,
				flag: {
					navbar: false,
				},
				isNavbarToggle: false,
				isFinished: false,
				arr_factor: [],
				user_data: {},
				is_show_otp: false,
			};
		},
		watch: {
			scrollY(val) {
				// console.log(this.base.responsive_scroll_threshold(500))
				this.flag.navbar = val >= this.base.responsive_scroll_threshold(200);
			},
		},
		async created() {
			var context = this
			this.base = new Base();

			window.addEventListener("scroll", this.handleScroll);
			this.scrollY = 1;

			var token = await localStorage.getItem("token");
			this.token = token;

			if (token != null) this.get_auth();



			// if(this.$route.path.match(/\/auth*/g)){
			// 	this.base.addStyle(this.base.arrLoginBaseCSS)
			// 	this.base.addScript(this.base.arrLoginBaseJS)
			// }
			// else{
			setTimeout(() => {
				this.base.addScript(this.base.arrBaseJS);

				// if(!this.$route.path.match(/\/property/g))
					setTimeout(() => {
						this.base.addScript(this.base.arrCustomJS);
						this.isFinished = true;
					}, 1000);
			}, 1000);
			// }

			const last_version = localStorage.getItem("version");
			if (
				last_version == null ||
				(last_version != null && last_version !== this.base.app_version)
			) {
				localStorage.setItem("version", this.base.app_version);
				window.location.reload(true);
			}
		},
		mounted(){

		},
		methods: {
			onChangeModal(is_show_otp) {
				this.is_show_otp = is_show_otp;
			},
			onLogoutClicked() {
				localStorage.removeItem("token");
				location.reload();
			},
			async get_auth() {
				var response = await this.base.request(this.base.url_api + "/auth/profile");

				if (response != null) {
					if (response.status === "success") {
						this.user_data = response.data;
					} else this.base.show_error(response.message);
				} else this.base.show_error(this.$t("server_error"));
			},
			onChangeArrFactor(arr) {
				this.arr_factor = arr;
			},
			onNavbarToggle() {
				this.isNavbarToggle = !this.isNavbarToggle;
			},
			handleScroll() {
				this.scrollY = window.scrollY;
			},
		},
	};
</script>

<style lang="scss">
	@media only screen and (max-width: 576px) {
		html,
		body {
			font-size: 12px;
		}
		.custom-navbar-padding {
			padding: 1.8rem 2rem;
		}
		.custom-navbar-padding-right {
			padding-right: 2rem;
		}
		.custom-navbar-padding-left {
			padding-left: 2rem;
		}
		.content-container {
			width: 100%;
			padding: 0;
		}
	}
	@media only screen and (min-width: 576px) and (max-width: 960px) {
		html,
		body {
			font-size: 12px;
		}
		.custom-navbar-padding {
			padding: 1.8rem 3rem;
		}
		.custom-navbar-padding-right {
			padding-right: 3rem;
		}
		.custom-navbar-padding-left {
			padding-left: 3rem;
		}
		.content-container {
			width: 100%;
			padding: 0 1rem;
		}
	}
	@media only screen and (min-width: 960px) and (max-width: 1517px) {
		html,
		body {
			font-size: 16px;
		}
		.custom-navbar-padding {
			padding: 1.8rem 10rem;
		}
		.custom-navbar-padding-right {
			padding-right: 10rem;
		}
		.custom-navbar-padding-left {
			padding-left: 10rem;
		}
		.content-container {
			width: $content-width;
			display: inline-block;
		}
	}
	@media only screen and (min-width: 1517px) {
		html,
		body {
			font-size: 18px;
		}
		.custom-navbar-padding {
			padding: 1.8rem 10rem;
		}
		.custom-navbar-padding-right {
			padding-right: 10rem;
		}
		.custom-navbar-padding-left {
			padding-left: 10rem;
		}
		.content-container {
			width: $content-width;
			display: inline-block;
		}
	}

	html,
	body {
		font-family: poppins-regular;
		overflow-x: hidden;
	}
	// table *{
	//   border: inherit;
	// }
	table p {
		margin: 0;
	}
	.form-control {
		height: 3rem;
	}
	button:focus {
		outline: none !important;
		box-shadow: none !important;
		border: none !important;
	}
	.general-title {
		font-family: poppins-bold;
		font-size: 2rem;
		text-transform: uppercase;
	}
	.general-subtitle {
		font-family: poppins-regular;
		font-size: 1.8rem;
	}
	.navigation {
		color: $gray8;
	}
	.navigation.navigation-now {
		font-family: poppins-medium;
		// text-decoration: underline;
		text-transform: uppercase;
	}
	// .select2-container .select2-selection--single {
	//   height: 2.3rem;
	// }
	// .select2-selection__arrow {
	//   height: 100% !important;
	// }
	// .select2-selection__rendered {
	//   height: 100% !important;
	//   display: flex !important;
	//   align-items: center;
	//   line-height: 100% !important;
	// }
	// .select2-results{
	//   z-index: 1;
	//   background-color: white;
	// }
	.navbar-fixed-enter-active,
	.navbar-fixed-leave-active {
		transition: all 0.5s;
	}
	.navbar-fixed-leave-to,
	.navbar-fixed-enter {
		margin-top: -10rem;
		opacity: 0;
	}

	.ql-size-small {
		font-size: 1.2rem !important;
	}
	.ql-size-large {
		font-size: 4.5rem !important;
	}
	.ql-size-huge {
		font-size: 5.25rem !important;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: 4.5rem !important;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: 5.25rem !important;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		font-size: 4.5rem !important;
	}

	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		font-size: 5.25rem !important;
	}
	.form-control:focus {
		border: 1px solid $gray13;
		box-shadow: none;
	}
	// .form-control,
	// .select2-results__option {
	// 	font-size: 1rem;
	// }
	// .select2-selection__rendered {
	// 	padding-top: 0.2rem;
	// 	font-size: 1rem;
	// }
	.select2 {
		width: 100% !important;
		.selection {
			.select2-selection--single {
        height: 3rem !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ced4da;
        .select2-selection__rendered {
          // height: 3rem !important;
          flex: 1;
					padding: 0.375rem 1rem;
				}
        .select2-selection__arrow{
          position: unset;
          inset: 0;
          padding: 0 .5rem;
          // padding: 0.375rem;
          b{
            position: unset;
            display: inline-flex;
            margin: 0;
            inset: 0;
          }
        }
			}
		}
		// .selection{
		//   .select2-selection{
		//   }
		// }
	}
  .select2-dropdown{
    font-size: 14px;
  }
	.form-group {
		font-size: 14px;
		label {
			color: var(--thm-black);
			font-weight: 500;
			line-height: 24px;
		}
		.form-control {
			font-size: inherit;
			padding: 0.375rem 1rem;
		}
		span {
			font-size: inherit;
		}
	}
	.btn{
		font-size: .8rem
	}
	.gap-2{
		gap: .5rem
	}
</style>
