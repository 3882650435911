<template>
	<div>
		<label class="m-0" v-if="title != null">{{ title }}</label>
		<label class="m-0">Image size must not exceed {{ max_size.toLocaleString(base.locale_string) }}MB</label>

		<div v-if="image_data != null && image_data.image_display != null">
			<img class="rounded mr-2" style="width: 10rem;" :src="image_data.image_display" data-holder-rendered="true" />
		</div>

		<div class="mt-3">
			<input type="file" class="d-none" accept="image/*" ref="input_image" @change="change_image($event)" />
			<button class="btn btn-primary" @click="choose_image">Choose Image</button>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
	props: ['title', 'image_data1', ],
	data(){
		return{
			base: null,
			image_data: {},
			max_size: 2,
		}
	},
	watch: {
		image_data(val){
			if(val != null && val.data_image != null)
				this.$emit('onResponse', val)
		},
		image_data1(val){
			this.image_data = val
		},
	},
	created(){
		this.base = new Base()
	},
	methods: {
		choose_image() {
			$(this.$refs['input_image']).trigger('click')
		},
		async change_image(event) {
			if(event.target.files[0].size / 1024 / 1024 > this.max_size)
				this.base.show_error('Your File exceed '+this.max_size+" MB")
			else{
				const image = new Image()
				image.src = URL.createObjectURL(event.target.files[0])

				var context = this
				this.base.toDataURL(URL.createObjectURL(event.target.files[0]), (response) => {
					context.image_data = {
						image_display: URL.createObjectURL(event.target.files[0]),
						data_image: response,
					}
				})
			}
		},
	}
}
</script>

<style lang="scss">
.custom-loading-container{
	background-color: $white;
	top: 0;
}
.custom-loading-enter-active, .custom-loading-leave-active{
	transition: all 2s;
}
.custom-loading-leave-to, .custom-loading-enter {
	opacity: 0;
}
</style>