<template>
  <div class="mobile-nav__wrapper">
			<div class="mobile-nav__overlay side-menu__toggler mobile-nav__toggler"></div>
			<div class="mobile-nav__content">
					<span class="mobile-nav__close side-menu__toggler mobile-nav__toggler">
							<i class="fa fa-times"></i>
					</span>
					<div class="logo-box">
							<a href="/" aria-label="logo image">
								<img :src="logo_data.url_image" alt="" style="width: 12rem;"/>
							</a>
					</div>
					<!-- /.logo-box -->
					<div class="mobile-nav__container clearfix"></div>
					<!-- /.mobile-nav__container -->
					<ul class="mobile-nav__contact list-unstyled">
							<li>
									<i class="icon-message"></i>
									<a :href="'mailto:' + email">{{ email }}</a>
							</li>
							<li>
									<i class="icon-phone-call"></i>
									<a :href="'tel:' + phone">{{ phone }}</a>
							</li>
					</ul><!-- /.mobile-nav__contact -->
					<div class="mobile-nav__top">
							<div class="mobile-nav__social">
									<a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
									<a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
									<a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
									<a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
							</div><!-- /.mobile-nav__social -->
					</div><!-- /.mobile-nav__top -->
			</div>
	</div>
</template>

<script>
import Base from '@/utils/base'
import $ from 'jquery'

export default{
  props: ['isNavbarToggle', ],
  data(){
    return{
      base: null,
      currentRoute: '/',
      arr_custom_navbar: [],
			logo_data: {},
			email: '',
			phone: '',
    }
  },
  watch: {
  },
  created(){
		var context = this
    this.base = new Base()
    this.currentRoute = this.$router.currentRoute.path

		this.get_setting()
		this.get_logo_data()

		setTimeout(() => {
			window.$('.logout').click(function() {
				context.onLogoutClicked()
			})
		}, 3000)
  },
  methods: {
    onLevel1Clicked(index1){
      // window.$('#custom_level1' + index1).dropdown('toggle')

      var arr = JSON.parse(JSON.stringify(this.arr_custom_navbar))
      arr[index1].is_show = !arr[index1].is_show
      this.arr_custom_navbar = arr
    },
    onLevel2Clicked(index1, index2){
      var arr = JSON.parse(JSON.stringify(this.arr_custom_navbar))
      arr[index1].arr[index2].is_show = !arr[index1].arr[index2].is_show
      this.arr_custom_navbar = arr
    },
    onRedirectLevel1(index1){
      window.localStorage.setItem('custom_navbar_level1', JSON.stringify(this.arr_custom_navbar[index1]))
      window.localStorage.setItem('custom_navbar_level2', JSON.stringify({}))
      window.localStorage.setItem('custom_navbar_level3', JSON.stringify({}))
    },
    onRedirectLevel2(index1, index2){
      window.localStorage.setItem('custom_navbar_level1', JSON.stringify(this.arr_custom_navbar[index1]))
      window.localStorage.setItem('custom_navbar_level2', JSON.stringify(this.arr_custom_navbar[index1].arr[index2]))
      window.localStorage.setItem('custom_navbar_level3', JSON.stringify({}))
    },
    onRedirectLevel3(index1, index2, index3){
      window.localStorage.setItem('custom_navbar_level1', JSON.stringify(this.arr_custom_navbar[index1]))
      window.localStorage.setItem('custom_navbar_level2', JSON.stringify(this.arr_custom_navbar[index1].arr[index2]))
      window.localStorage.setItem('custom_navbar_level3', JSON.stringify(this.arr_custom_navbar[index1].arr[index2].arr[index3]))
    },
		onLogoutClicked() {
			localStorage.removeItem("token");
			location.reload();
		},
    async get_navbar(){
      var response = await this.base.request(this.base.url_api + "/navbar/level1/all")

      if(response != null){
        if(response.status === "success"){
          for(let level1 of response.data){
            level1.id_frontend = level1.name.replace(' ', '_').toLowerCase()
            level1.is_show = false
            level1.arr = level1.navbar_level2
            for(let level2 of level1.arr){
              level2.id_frontend = level2.name.replace(' ', '_').toLowerCase()
              level2.is_show = false
              level2.arr = level2.navbar_level3
              for(let level3 of level2.arr){
                level3.id_frontend = level3.name.replace(' ', '_').toLowerCase()
                level3.is_show = false
              }
            }
          }
          this.arr_custom_navbar = response.data
        }
        else
          this.base.show_error(response.message)
      }
      // else
      //   this.base.show_error(this.$t('server_error'))
    },
		async get_setting(){
			var response = await this.base.request(this.base.url_api + "/setting")

			if(response != null){
				if(response.status === "success"){
					for(let setting of response.data){
						if(setting.key === "email")
							this.email = setting.value
						else if(setting.key === "phone")
							this.phone = setting.value
					}
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
		async get_logo_data(){
			var response = await this.base.request(this.base.url_api + "/info/logo-footer")

			if(response != null){
				if(response.status === "success"){
					this.logo_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
  }
}
</script>

<style lang="scss">
@media (max-width: 992px) {
  .navbar-collapse {
    position: fixed;
    z-index: 1;
    top: 0;
    left: -75%;
    padding-bottom: 3rem;
    width: 75%;
    height: 100%;
    background-color: $white;
  }

  .navbar-collapse {
    left: -75%;
    transition: left 500ms ease-in-out;
  }

  .navbar-collapse.button-toggle {
    left: 0;
    transition: left 500ms ease-in-out;
  }
}
.navbar-side{
  border-radius: 0;
}
.navbar-side .list-group-item{
  border-right: none;
  border-left: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
