import Vue from "vue";
import Router from "vue-router";
// import router_view from '@/router_view'

import home from "@/pages/home/index";

import property from "@/pages/property/detail";
import property_list from "@/pages/property/index";
import property_chat from "@/pages/property/chat";

import change_profile from "@/pages/profile/change_profile";
import change_password from "@/pages/profile/change_password";

import offer_property from "@/pages/offer_property/index";

import login from "@/pages/auth/login";
import register from "@/pages/auth/register";
import forget_password from "@/pages/auth/forget_password";
import otp from "@/pages/auth/otp";

import contact from "@/pages/contact";
import about_us from "@/pages/about_us";

Vue.use(Router);

var router = new Router({
  routes: [
    {
      path: "",
      component: home,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/property",
      component: property,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/property/list",
      component: property_list,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/property/chat",
      component: property_chat,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/profile/change-profile",
      component: change_profile,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/profile/change-password",
      component: change_password,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/auth/login",
      component: login,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/auth/register",
      component: register,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/auth/forget-password",
      component: forget_password,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/auth/otp",
      component: otp,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/property/offer",
      component: offer_property,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/contact",
      component: contact,
      meta: {
        requiresAuth: false,
      },
    },
		{
			path: "/about-us",
			component: about_us,
			meta: {
				requiresAuth: false,
			},
		},
  ],
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      sessionStorage.setItem("redirect", to.fullPath);
      next({
        path: "/auth/login",
      });
    } else next();
  } else next();
});

export default router;
