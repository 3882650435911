var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"listing_details_top pb-0"},[_c('div',{staticClass:"container"},[_c('custom-navigation'),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-lg-7 col-xl-8"},[_c('div',{staticClass:"listing_details_top_left"},[_c('div',{staticClass:"listing_details_top_title"},[(_vm.data.property_type != null)?_c('span',{staticClass:"badge px-3 py-1 mr-2 rounded-sm bg-primary text-white"},[_vm._v(_vm._s(_vm.data.property_type.name))]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.status != 'on_progress'),expression:"data.status != 'on_progress'"}],staticClass:"badge px-3 py-1 rounded-sm bg-primary text-white"},[_vm._v(_vm._s(_vm.data.status_format))]),_c('h3',[_vm._v(_vm._s(_vm.data.name))]),_c('p',[_vm._v(_vm._s(_vm.data.address))]),_c('h4',[_vm._v("Rp. "+_vm._s(_vm.data.price.toLocaleString(_vm.base.locale_string)))]),_c('p',[_vm._v("Property ID: "),_c('span',[_vm._v(_vm._s(_vm.data.id))])])])])]),_c('div',{staticClass:"col-lg-5 col-xl-4"},[_c('div',{staticClass:"listing_details_top_right clearfix"},[_c('div',{staticClass:"listing_details_top_product_list_box w-100 m-0"},[_c('ul',{staticClass:"listing_details_top_product_list list-unstyled"},[_c('li',[_vm._m(0),_c('div',{staticClass:"text_box"},[_c('h5',[_vm._v(_vm._s(_vm.data.bedroom.toLocaleString(_vm.base.locale_string)))])])]),_c('li',[_vm._m(1),_c('div',{staticClass:"text_box"},[_c('h5',[_vm._v(_vm._s(_vm.data.bathroom.toLocaleString(_vm.base.locale_string)))])])]),_c('li',[_vm._m(2),_c('div',{staticClass:"text_box d-flex"},[_c('h5',[_vm._v(_vm._s(_vm.data.land_size.toLocaleString(_vm.base.locale_string)))]),_vm._v("  "),_vm._m(3)])]),_c('li',[_vm._m(4),_c('div',{staticClass:"text_box d-flex"},[_c('h5',[_vm._v(_vm._s(_vm.data.building_size.toLocaleString(_vm.base.locale_string)))]),_vm._v("  "),_vm._m(5)])])])])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon_box"},[_c('span',{staticClass:"icon-bed"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon_box"},[_c('span',{staticClass:"icon-shower"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon_box"},[_c('span',{staticClass:"icon-square-measument"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("m"),_c('sup',[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon_box"},[_c('span',{staticClass:"icon-home"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("m"),_c('sup',[_vm._v("2")])])
}]

export { render, staticRenderFns }