<template>

	<div class="container my-5">
		<custom-navigation />

		<div class="card p-0 mt-3" id="offer-card">
			<div class="card-header p-4">
				<div class="grid">
					<div class="">
						<img :src="offer_property.property.url_image" />
					</div>
					<div class="">
						<h3 id="title">{{ offer_property.property.name }}</h3>
						<p id="address">{{ offer_property.property.address }}</p>
						<p class="font-weight-bold text-primary">{{ offer_property.property.price.toLocaleString(base.locale_string) }}</p>

						<small class="m-0">Anda Menawar :</small>
						<h3 class="font-weight-bold text-primary">Rp. {{ offer_property.price_format }}</h3>
						<small>{{ offer_property.property.updated_at_format }}</small>
						<!-- <h4>Offer Price: Rp. {{ offer_property.price.toLocaleString(base.locale_string) }}</h4> -->
					</div>
				</div>
				<!-- <div class="d-flex align-items-center">
					<div class="ml-3">
					</div>
				</div> -->
			</div>

			<div class="card-body">
				<div v-for="(chat_room, index) in arr" :key="index">
					<div class="d-flex justify-content-end align-items-end mb-3" v-if="user_data.id == chat_room.sender_id">
						<p class="m-0">{{ chat_room.created_at_moment.format('HH:mm') }}</p>
						<div class="px-3 py-1 rounded-lg bg-secondary ml-1">
							<p class="m-0 text-white">{{ chat_room.message }}</p>
						</div>

					</div>
					<div class="d-flex justify-content-start align-items-center mb-3" v-else>

						<div class="px-3 py-1 rounded-lg bg-primary mr-1">
							<p class="m-0 text-white">{{ chat_room.message }}</p>
						</div>
						<p class="m-0">{{ chat_room.created_at_moment.format('HH:mm') }}</p>
					</div>
				</div>
				<div class="d-flex">
					<input type="text" v-model="message" class="form-control mr-3" placeholder="Tulis pesan yang ingin disampaikan" />
					<button class="btn btn-primary mr-1" @click="onSubmit">Kirim</button>
				</div>
				<!-- <div class="row">
					<div class="col-12 mt-3">
					</div>

					<div class="col-12 mt-3">
					</div>

				</div> -->
			</div>
		</div>
	</div>

</template>

<script>
import Base from '@/utils/base';
import moment from 'moment'

import CustomNavigation from '@/layout/custom_navigation';

export default {
	components: {
		'custom-navigation': CustomNavigation,
	},
	props: ['isFinished',],
	data() {
		return {
			base: null,
			arr: [],
			user_data: {},
			message: '',
			offer_property: {},
		}
	},
	watch: {
		isFinished(val) {
			if (val) {
				this.get_data()
				this.get_profile_data()
				this.get_chat_room_data()
			}
		},
	},
	async mounted() {
		this.base = new Base()
		this.base.save_current_page('Offer Property')

	},
	methods: {
		async get_data() {
			window.$('#please_wait_modal').modal('show')
			var response = await this.base.request(this.base.url_api + '/property/offer?id=' + this.$route.query.id)

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)
			if (response != null) {
				if (response.status == "success") {
					this.offer_property = response.data
				}
			}
		},
		async get_profile_data() {
			window.$('#please_wait_modal').modal('show')
			var response = await this.base.request(this.base.url_api + '/auth/profile')

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)
			if (response != null) {
				if (response.status == "success") {
					var response_data = response.data
					this.user_data = response_data
				}
			}
		},
		async get_chat_room_data() {
			window.$('#please_wait_modal').modal('show')
			var response = await this.base.request(this.base.url_api + '/chat/room?offer_property_id=' + this.$route.query.id)

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)
			if (response != null) {
				if (response.status == "success") {
					var arr = []
					for (let data of response.data) {
						data.created_at_moment = moment(data.created_at)
						arr.splice(0, 0, data)
					}
					this.arr = arr
				}
			}
		},
		async onSubmit() {
			// window.$('#please_wait_modal').modal('show')
			var message = this.message
			this.message = ''
			var response = await this.base.request(this.base.url_api + '/chat/room', 'post', {
				message: message,
				offer_property: {
					id: this.$route.query.id,
				},
			});

			// setTimeout(() => {
			// 	window.$('#please_wait_modal').modal('hide')
			// }, 500)
			if (response != null) {
				if (response.status == "success") {
					response.data.created_at_moment = moment(response.data.created_at)
					this.arr.push(response.data)

				}
			}
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first {
		padding-right: .5rem;
	}

	.article-last {
		padding-right: .5rem;
	}

	.article-second {
		padding-left: .5rem;
	}
}

@media only screen and (min-width: 720px) {
	.article-first {
		padding-right: .5rem;
	}

	.article-last {
		padding-left: .5rem;
	}

	.article-second {
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
	transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
	transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
	transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}

#offer-card {
	.card-header {
		background: white;

		img {
			width: 150px;
			aspect-ratio: 1 / 1;
			object-fit: cover;
			object-position: center;
		}

		#title {
			color: var(--primary);
			font-size: 20px;
			font-weight: bold;
		}
		.grid{
			display: grid;
			grid-template-columns: 150px 1fr;
			gap: 1rem;
		}
	}
}
</style>
