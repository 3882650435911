<template>
  <div>
		<!--Listings Page Content Single-->
		<div class="latest_properties_single" v-show="type == 'grid'">
			<div>
				<div class="" v-show="!is_loading">
					<div class=""  v-show="data.image.length > 0">
						<img :src="data.image[0].url_image" alt="" class="item-grid-owl-image" />
					</div>
					<img :data-src="require('@/assets/no_image.png')"  v-show="data.image.length == 0" class="owl-lazy item-grid-owl-image" />
				</div>
				<img :src="require('@/assets/no_image.png')" class="item-grid-owl-image" v-show="is_loading" />
			</div>
			<div class="latest_properties_content">
				<div class="latest_properties_top_content">
					<h4>
						<a :href="'/property?id=' + data.id">{{ data.name }}</a>
					</h4>
					<h3>Rp. {{ data.price.toLocaleString(base.locale_string) }}</h3>
				</div>
				<div class="d-flex justify-content-between flex-column gap-2">
					<div class="px-3 py-1 thm-btn align-self-start">
						<p class="m-0 " v-show="data.status == 'sold'">Terjual</p>
						<p class="m-0" v-show="data.status == 'on_progress'">Dijual</p>
					</div>
		
					<div class="latest_properties_bottom_content">
						<ul class="list-unstyled">
							<li>
								<span class="icon-bed"></span>{{ data.bedroom.toLocaleString(base.locale_string) }}
							</li>
							<li>
								<span class="icon-shower"></span>{{ data.bathroom.toLocaleString(base.locale_string) }}
							</li>
							<li>
								<span class="icon-square-measument"></span>{{
									data.land_size.toLocaleString(base.locale_string) }} m<sup>2</sup>
							</li>
							<li>
								<span class="icon-square-measument"></span>{{
									data.building_size.toLocaleString(base.locale_string) }} m<sup>2</sup>
							</li>
						</ul>
					</div>
		
				</div>
		
				<div class="mt-3" v-if="list_type == 'property_list'">
					<button type="button" @click="$emit('onMakeOfferClicked', index)"
						v-if="data.status == 'on_progress' && data.own_offer == null"
						class="thm-btn w-100">Ditawar aja</button>
		
					<button type="button" @click="$emit('onOfferListClicked', index)"
						v-else-if="data.status == 'on_progress' && data.own_offer != null"
						class="thm-btn w-100">Penawaran Anda</button>
				</div>
		
			</div>
		</div>
		
		<div class="latest_properties_single" v-show="type == 'list'">
			<div class="latest_properties_content property-item-list">
				<div>
					<div class="" v-show="!is_loading">
						<div class="" v-show="data.image.length > 0">
							<img :src="data.image[0].url_image" alt="" class="item-list-owl-image" />
						</div>
						<img :data-src="require('@/assets/no_image.png')" v-show="data.image.length == 0" class="owl-lazy item-list-owl-image" />
					</div>
					<img :src="require('@/assets/no_image.png')" class="item-list-owl-image" v-show="is_loading" />
				</div>
				<div class="py-3 pr-3">
					<div class="px-3 py-1 thm-btn mb-2">
						<p class="m-0 " v-show="data.status == 'sold'">Terjual</p>
						<p class="m-0" v-show="data.status == 'on_progress'">Dijual</p>
					</div>
					<div class="latest_properties_top_content mb-0 pb-0 border-0">
						<h4>
							<a :href="'/property?id=' + data.id">{{ data.name }}</a>
						</h4>
						<!-- <h3>Rp. {{ data.price.toLocaleString(base.locale_string) }}</h3> -->
						<p class="font-weight-bold text-primary">Rp. {{ data.price.toLocaleString(base.locale_string) }}
						</p>
					</div>
					<div class="latest_properties_bottom_content">
						<ul class="list-unstyled list_mode">
							<li>
								<span class="icon-bed"></span>{{ data.bedroom.toLocaleString(base.locale_string) }}
							</li>
							<li class="">
								<span class="icon-shower"></span>{{ data.bathroom.toLocaleString(base.locale_string) }}
							</li>
							<li class="">
								<span class="icon-square-measument"></span>{{
									data.land_size.toLocaleString(base.locale_string) }} m<sup>2</sup>
							</li>
							<li class="">
								<span class="icon-square-measument"></span>{{
									data.building_size.toLocaleString(base.locale_string) }} m<sup>2</sup>
							</li>
						</ul>
					</div>
					
					<div class="listings_two_page_bottom_content_top mb-0 pb-0 border-bottom-0 border-top pt-2 mt-2" v-if="list_type == 'offer_property'">
						<!-- <h4><a :href="'/property/chat?id=' + data.offer_property_id">{{ data.name }}</a></h4> -->
						<small class="m-0">Anda Menawar :</small>
						<h3>Rp. {{ offer_data.price.toLocaleString(base.locale_string) }}</h3>
						<small>{{ offer_data.updated_at_format }}</small>
					</div>
					
					<div class="mt-3" v-else-if="list_type == 'property_list'">
						<button type="button" @click="$emit('onMakeOfferClicked', index)"
							v-if="data.status == 'on_progress' && data.own_offer == null"
							class="thm-btn w-100">Ditawar aja</button>
		
						<button type="button" @click="$emit('onOfferListClicked', index)"
							v-else-if="data.status == 'on_progress' && data.own_offer != null"
							class="thm-btn w-100">Penawaran Anda</button>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import Base from "@/utils/base";
	
export default {
  props: ['data', 'offer_data', 'index', 'type', 'list_type', 'is_loading', ],
  data(){
    return{
			base: null,
    }
  },
  created(){
    this.base = new Base();
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
</style>