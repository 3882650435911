<script>
	// import moment from 'moment'

	import Base from "@/utils/base";

	export default {
		data() {
			return {
				base: null,
				phone: "",
				submitted: false,
				is_please_wait: false,
			};
		},
		watch: {
			phone(val) {
				this.phone = this.base.phone_validation(val);
			},
		},
		async created() {
			this.base = new Base();
		},
		methods: {
			async submit() {
				if (this.phone == "") this.base.show_error("Phone is Empty");
				else {
					this.is_please_wait = true;
					var response = await this.base.request(this.base.url_api + "/auth/register", "post", {
						phone: "+62" + this.phone,
						type: "customer",
					});
					this.is_please_wait = false;

					if (response != null) {
						if (response.status == "success") {
							await localStorage.setItem("user", JSON.stringify(response.data));

							this.$emit("changeModal", true);
							window.$("#register-modal").modal("hide");
							window.$("#otp-modal").modal("show");
						} else this.base.show_error(response.message);
					} else this.base.show_error("Server Error");
				}
			},
			onBackClicked() {
				this.base.back_page_redirect();
			},
		},
	};
</script>

<template>
	<div class="row bg-white rounded-lg p-5 justify-content-center position-relative">
		<button
			type="button"
			class="close position-absolute w-auto"
			data-dismiss="modal"
			aria-label="Close"
			style="right: 0; top: 0; opacity: 1; background-color: var(--thm-base);">
			<img src="@/assets/login_close.png" />
		</button>
		<!-- <div class="col-12 col-md-6 d-flex align-items-center justify-content-center"> -->
		<!-- <div class="col-auto d-flex align-items-center justify-content-center">
			<img src="@/assets/logo_new.png" style="max-width: 240px;">
		</div> -->

		<div class="col-auto col-lg d-flex align-items-center justify-content-center position-relative">
			<!-- <a href="/" class="position-absolute" style="top: 1rem; right: 0rem;">
			</a> -->
			<!-- <div style="max-width: 25.6rem;"> -->
			<div style="">
				<p class="text-primary login-title">Register</p>
				<!-- <p class="m-0 text-white login-detail">Register your account</p> -->

				<div class="form-group mt-3">
					<p class="mb-2 login-detail">Phone</p>
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text" id="basic-addon1">+62</span>
						</div>
						<input type="text" v-model="phone" class="form-control" />
					</div>
				</div>

				<div class="mt-4">
					<button class="btn btn-primary text-white w-100" @click="submit">Register</button>
					<!-- <p class="m-0 text-white login-forget mt-3">By clicking on Sign up, you agree to our Terms of service and Privacy policy.</p> -->
				</div>

				<div class="d-flex align-items-center my-3">
					<div class="login-hr"></div>
					<p class="m-0 text-primary login-detail mx-3">or</p>
					<div class="login-hr"></div>
				</div>

				<div>
					<!-- <a href="/auth/register" class="btn btn-white border-primary text-white login-reset-password w-100">{{$t('create_account') }}</a> -->
					<a
						href="#"
						class="btn btn-primary text-white login-reset-password w-100"
						data-dismiss="modal"
						data-toggle="modal"
						data-target="#login-modal"
						>Login</a
					>
				</div>
			</div>
		</div>
	</div>
</template>
