<template>
  <div class="search-popup">
			<div class="search-popup__overlay custom-cursor__overlay">
					<div class="cursor"></div>
					<div class="cursor-follower"></div>
			</div><!-- /.search-popup__overlay -->
			<div class="search-popup__inner">
					<form action="#" class="search-popup__form">
							<input type="text" name="search" v-model="search" placeholder="Type here to Search....">
							<button type="button" @click="submit"><i class="fa fa-search"></i></button>
					</form>
			</div>
	</div>
</template>

<script>
import Base from '@/utils/base'
export default{
  props: [],
  data(){
    return{
      base: null,
			search: '',
    }
  },
  watch: {
  },
  created(){
    this.base = new Base()
  },
  methods: {
		submit(){
			if(this.search != ''){
				window.location.href = "/property/list?search=" + this.search
			}
		},
  }
}
</script>

<style lang="scss">
@media (max-width: 992px) {
  .navbar-collapse {
    position: fixed;
    z-index: 1;
    top: 0;
    left: -75%;
    padding-bottom: 3rem;
    width: 75%;
    height: 100%;
    background-color: $white;
  }

  .navbar-collapse {
    left: -75%;
    transition: left 500ms ease-in-out;
  }

  .navbar-collapse.button-toggle {
    left: 0;
    transition: left 500ms ease-in-out;
  }
}
.navbar-side{
  border-radius: 0;
}
.navbar-side .list-group-item{
  border-right: none;
  border-left: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
