<template>
	<div class="page-wrapper">
		<section class="contact">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6 col-lg-6 col-xl-5">
						<div class="block-title text-left">
							<h4>{{ contact_us_data.title }}</h4>
							<h2>{{ contact_us_data.subtitle }}</h2>
						</div>
						<div class="contact_text">
							<p>{{ contact_us_data.content }}</p>
						</div>
						<!-- <div class="contact__social">
							<a href="#"><i class="fab fa-twitter"></i></a>
							<a href="#"><i class="fab fa-facebook-square"></i></a>
							<a href="#"><i class="fab fa-dribbble"></i></a>
							<a href="#"><i class="fab fa-instagram"></i></a>
						</div> -->
					</div>
					<div class="col-12 col-md-6 col-lg-6 col-xl-7">
						<div class="contact__form ml-3">
							<div class="row">
								<div class="col-xl-6">
									<div class="comment_input_box">
										<input type="text" placeholder="Your name" v-model="name" name="name">
									</div>
								</div>
								<div class="col-xl-6">
									<div class="comment_input_box">
										<input type="email" placeholder="Email address" v-model="email" name="email">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-6">
									<div class="comment_input_box">
										<input type="text" placeholder="Phone number" v-model="phone" name="phone">
									</div>
								</div>
								<div class="col-xl-6">
									<div class="comment_input_box">
										<input type="email" placeholder="Subject" v-model="subject" name="Subject">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-12">
									<div class="comment_input_box">
										<textarea name="message" placeholder="Write message" v-model="message"></textarea>
									</div>
									<button type="submit" class="thm-btn comment-form__btn" @click="submit">Kirim</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Base from '@/utils/base';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Contact',
	data() {
		return {
			base: null,
			name: '',
			email: '',
			phone: '',
			subject: '',
			message: '',
			contact_us_data: {},
		};
	},
	watch: {
		phone(val){
			this.phone = this.base.phone_validation(val, 12, false)
		},
	},
	created(){
		this.base = new Base()
		this.get_contact_data()
	},
	methods: {
		async get_contact_data(){
			var response = await this.base.request(this.base.url_api + "/info/contact-us")
		
			if(response != null){
				if(response.status === "success"){
					this.contact_us_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
		async submit(){
			if(this.name == '')
				this.base.show_error('Name is Empty')
			else if(this.phone == '')
				this.base.show_error('Phone is Empty')
			else if(this.email == '')
				this.base.show_error('Email is Empty')
			else if(this.subject == '')
				this.base.show_error('Subject is Empty')
			else if(this.message == '')
				this.base.show_error('Message is Empty')
			else if(!this.base.validate_email(this.email))
				this.base.show_error('Email is not in Email Format')
			else{
				window.$('#please_wait_modal').modal('show')
				
				var response = await this.base.request(this.base.url_api + "/contact-us", 'post', {
					name: this.name,
					phone: this.phone,
					email: this.email,
					subject: this.subject,
					message: this.message,
				})
				
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)
				
				if (response != null) {
					if (response.status === "success") {
						this.base.show_error("Message Sent")
						window.location.reload()
					}
					else
						this.base.show_error(response.message)
				}
				else
					this.base.show_error(this.$t('server_error'))
			}
		},
	},
});
</script>

<style lang="scss" scoped>
	.contact{
		padding-top: 120px;
		background-color: white;
	}
</style>
