<template>
	<div class="site-header__header-one-wrap clearfix">
		<div class="header_top_one">
			<div class="container">
				<div class="header_top_one_inner d-flex flex-row align-items-center">
					<div class="header_top_one_logo_box float-left">
						<div class="header_top_one_logo">
							<a href="/"><img :src="logo_data.url_image" alt="" style="width: 12rem" /></a>
						</div>
					</div>
					<div class="header_top_one_content_box float-right">
						<div class="header_top_one_content_box_top clearfix">
							<div class="header_top_one_content_box_top_left float-left">
								<p v-if="user_data.id == null">Welcome to DITAWARAJA</p>
								<p
									v-if="token != null && token != '' && user_data.id != null"
									href="#"
									style="font-size: 14px; color: var(--thm-gray)">
									Hello {{ user_data.phone }}
								</p>
								<!-- <div>
								</div> -->
								<!-- <div class="" v-if="token != null && token != ''">
								</div> -->
							</div>
							<div
								class="header_top_one_content_box_top_right float-right"
								v-if="token != null && token != ''">
								<ul class="list-unstyled header_top_one_content_box_top_right_list">
									<!-- <li v-if="user_data.id != null">
										<a href="#">Hello {{ user_data.phone }}</a>
									</li> -->
									<li><a href="/property/offer">List Offer</a></li>
									<li><a href="/profile/change-profile">Change Profile</a></li>
									<li><a href="#!" @click="onLogoutClicked">Logout</a></li>
								</ul>
							</div>
						</div>
						<div class="header_top_one_content_box_bottom">
							<div
								class="header_top_one_content_box_bottom_inner d-flex flex-row justify-content-between align-items-center">
								<div class="header_top_one_content_box_bottom__social_box">
									<div class="header_top_one_content_box_bottom__social">
										<a href="#"><i class="fab fa-twitter"></i></a>
										<a href="#"><i class="fab fa-facebook-square"></i></a>
										<a href="#"><i class="fab fa-dribbble"></i></a>
										<a href="#"><i class="fab fa-instagram"></i></a>
									</div>
								</div>
								<div class="header_top_one_content_box_bottom_contact_info">
									<ul class="header_top_one_content_box_bottom_contact_info_list list-unstyled">
										<li>
											<div class="icon">
												<span class="icon-phone-call" style="color: #002f5f"></span>
											</div>
											<div class="text">
												<p>Hubungi Kami</p>
												<a :href="'tel:' + phone">{{ phone }}</a>
											</div>
										</li>
										<li>
											<div class="icon">
												<span class="icon-message" style="color: #002f5f"></span>
											</div>
											<div class="text">
												<p>Kirim Email</p>
												<a :href="'mailto:' + email">{{ email }}</a>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<header class="main-nav__header-one">
			<div class="container">
				<nav class="header-navigation one stricky">
					<div class="container-box clearfix">
						<!-- Brand and toggle get grouped for better mobile display -->
						<div class="main-nav__left main-nav__left_one float-left">
							<a href="#" class="side-menu__toggler">
								<i class="fa fa-bars"></i>
							</a>
							<div class="main-nav__main-navigation one clearfix">
								<ul class="main-nav__navigation-box float-left">
									<li class="dropdown">
										<a href="/">Home</a>
									</li>
									<li>
										<a href="/about-us">Tentang Kami</a>
									</li>
									<li class="dropdown">
										<a href="/property/list">List Properti</a>
									</li>
									<li>
										<a href="/contact">Kontak Kami</a>
									</li>
									<li class="d-xl-none" v-if="token != null && token != ''"><a href="/property/offer">List Offer</a></li>
									<li class="d-xl-none" v-if="token != null && token != ''"><a href="/profile/change-profile">Change Profile</a></li>
									<li class="d-xl-none logout" v-if="token != null && token != ''"><a  href="#!">Logout</a></li>
								</ul>
							</div>
							<!-- /.navbar-collapse -->
						</div>
						<div class="main-nav__right main-nav__right_one float-right">
							<div class="header_btn_1">
								<a
									href="#"
									@click="onLoginClicked"
									class="thm-btn"
									style="background-color: #002f5f"
									v-if="token == null || token == ''"
									>Login</a
								>
							</div>
							<!-- <div class="icon_cart_box">
															<a href="#">
																	<span class="icon-shopping-cart"></span>
															</a>
													</div> -->
							<!-- <div class="icon_search_box">
								<a href="#" class="main-nav__search search-popup__toggler">
									<i class="icon-magnifying-glass"></i>
								</a>
							</div> -->
						</div>
					</div>
				</nav>
			</div>
		</header>
	</div>
</template>

<script>
	import Base from "@/utils/base";
	export default {
		props: ["scrollY"],
		data() {
			return {
				base: null,
				currentRoute: "/",
				customClass: "navbar-home-gray1",
				isNavbarToggle: false,
				arr_custom_navbar: [],
				email: "",
				phone: "",
				token: "",
				user_data: {},
				logo_data: {},
			};
		},
		async created() {
			var context = this
			this.base = new Base();

			var token = await localStorage.getItem("token");
			this.token = token;

			if (token != null) this.get_auth();
			this.get_setting();
			this.get_logo_data();


		},
		methods: {
			onLoginClicked() {
				// window.localStorage.setItem('url_redirect', this.$route.fullPath)
				// location.href = '/auth/login'

				window.$("#login-modal").modal("show");
			},
			onLogoutClicked() {
				localStorage.removeItem("token");
				location.reload();
			},
			async get_auth() {
				var response = await this.base.request(this.base.url_api + "/auth/profile");

				if (response != null) {
					if (response.status === "success") {
						this.user_data = response.data;
					} else this.base.show_error(response.message);
				} else this.base.show_error(this.$t("server_error"));
			},
			async get_setting() {
				var response = await this.base.request(this.base.url_api + "/setting");

				if (response != null) {
					if (response.status === "success") {
						for (let setting of response.data) {
							if (setting.key === "email") this.email = setting.value;
							else if (setting.key === "phone") this.phone = setting.value;
						}
					} else this.base.show_error(response.message);
				}
				// else
				//   this.base.show_error(this.$t('server_error'))
			},
			async get_logo_data() {
				var response = await this.base.request(this.base.url_api + "/info/logo");

				if (response != null) {
					if (response.status === "success") {
						this.logo_data = response.data;
					} else this.base.show_error(response.message);
				}
				// else
				//   this.base.show_error(this.$t('server_error'))
			},
		},
	};
</script>

<style lang="scss">
	@media only screen and (max-width: 960px) {
		.nav-link {
			padding-left: 0.5rem !important;
			padding-right: 0.5rem !important;
		}
	}
	@media only screen and (min-width: 960px) {
		.nav-master-dropdown:hover > .nav-dropdown > .dropdown-menu {
			display: block;
		}
		.nav-link {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}
	}

	.navbar-header * {
		font-family: poppins-bold;
	}
	.navbar-header a,
	.list-group a {
		text-decoration: none;
		color: $black;
	}
	.navbar-header button {
		border-radius: 0.5rem;
	}
	.navbar-home-normal-gray1 {
		background-color: $gray1;
	}
	.navbar-home-normal-white {
		background-color: $white;
	}
	.navbar-home-gray1 {
		transition: all 1s;
		background-color: $navbar-gray1;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}
	.navbar-home-white {
		transition: all 1s;
		background-color: $navbar-white;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}
	.navbar-home-gray4 {
		transition: all 1s;
		background-color: $navbar-gray4;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}

	.nav-dropdown .dropdown-toggle::after {
		display: none;
	}
	.nav-dropdown .dropdown-item,
	.nav-arrow {
		color: $black;
		font-family: poppins-medium;
		font-size: 0.8rem;
	}
	.nav-link {
		color: $black;
		font-family: poppins-medium;
	}
	.nav-link.active {
		color: $primary !important;
		font-family: poppins-bold;
	}
	.nav-dropdown .dropdown-item.active {
		background-color: $primary !important;
		font-size: 0.8rem;
		color: $white !important;
		font-family: poppins-bold;
	}
</style>
