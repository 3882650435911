<template>
	<div>
		<footer class="site_footer" :style="{backgroundColor: '#002F5F'}">
				<div class="container">
						<div class="site_footer_one_top">
								<div class="row">
										<div class="col-lg-6 fadeInUp" data-wow-delay="00ms">
												<div class="footer-widget__column footer_widget__about">
														<div class="footer_logo">
															<a href="/"><img :src="logo_data.url_image" alt="" style="width: 12rem;"/></a>
														</div>
														<div class="footer_widget_about_text">
																<p>{{ footer_data.content }}</p>
														</div>
														<div class="footer_call_agent_box">
																<div class="icon">
																		<span class="icon-phone-call"></span>
																</div>
																<div class="text">
																		<p>Hubungi Kami</p>
																		<a :href="'tel:' + phone">{{ phone }}</a>
																</div>
														</div>
												</div>
										</div>
										<div class="col-lg-4 d-none fadeInUp" data-wow-delay="200ms">
												<div class="footer-widget__column footer_widget__explore clearfix">
														<div class="footer-widget__title">
																<h3>Explore</h3>
														</div>
														<ul class="footer_widget__explore_list list-unstyled">
															<li><a href="#">Kontak Kami Us</a></li>
															<li v-show="user_data.id != null"><a href="/property/offer">List Offer</a></li>
															<li v-show="user_data.id != null"><a href="/profile/change-password">Change Password</a></li>
															<li v-show="user_data.id != null"><a href="/profile/change-profile">Change Profile</a></li>
														</ul>
														<!-- <ul class="footer_widget__explore_list two list-unstyled">
																<li><a href="#">Our Agents</a></li>
																<li><a href="#">Kontak Kami</a></li>
														</ul> -->
												</div>
										</div>
										<!-- <div class="col-xl-5 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
												<div class="footer-widget__column footer_widget__newsletter">
														<div class="footer-widget__title">
																<h3>Newsletter</h3>
														</div>
														<form action="#" class="footer_form">
																<div class="footer_input_box">
																		<input type="email" name="email" placeholder="Email Address">
																		<button type="submit" class="button">Subscribe</button>
																</div>
														</form>
														<div class="footer_widget__newsletter_bottom">
																<p>88 Broklyn Golden Street, New York. USA</p>
																<a href="mailto:info@baf.com">info@baf.com</a>
														</div>
												</div>
										</div> -->
								</div>
						</div>
				</div>
		</footer>
		
		<div class="site_footer_bottom">
				<div class="container">
						<div class="row">
								<div class="col-xl-12">
										<div class="site_footer_inner">
												<div class="site_footer_left">
														<p>© Copyright 2024 by <a href="#">DITAWARAJA</a></p>
												</div>
												<div class="site_footer__social">
														<a href="#"><i class="fab fa-twitter"></i></a>
														<a href="#"><i class="fab fa-facebook-square"></i></a>
														<a href="#"><i class="fab fa-dribbble"></i></a>
														<a href="#"><i class="fab fa-instagram"></i></a>
												</div>
										</div>
								</div>
						</div>
				</div>
		</div>
	</div>
</template>

<script>
import Base from '@/utils/base';

import FooterImage from '@/assets/images/resources/site_footer_top_bg.jpg';

export default {
	props: ['user_data', ],
  components: {
  },
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, ],
      email: '',
      phone: '',
			footerImage: FooterImage,
			logo_data: {},
			footer_data: {},
    }
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1

    this.get_setting()
		this.get_logo_data()
		this.get_footer_data()
  },
  methods: {
    onClickPhone(){
      location.href = 'tel:' + this.phone
    },
    async get_setting(){
      var response = await this.base.request(this.base.url_api + "/setting")

      if(response != null){
        if(response.status === "success"){
          for(let setting of response.data){
            if(setting.key === "email")
              this.email = setting.value
            else if(setting.key === "phone")
              this.phone = setting.value
          }
        }
        else
          this.base.show_error(response.message)
      }
      // else
      //   this.base.show_error(this.$t('server_error'))
    },
		async get_footer_data(){
			var response = await this.base.request(this.base.url_api + "/info/footer")
		
			if(response != null){
				if(response.status === "success"){
					this.footer_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
		async get_logo_data(){
			var response = await this.base.request(this.base.url_api + "/info/logo-footer")
		
			if(response != null){
				if(response.status === "success"){
					this.logo_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 960px) {
  .footer-content-card{
    margin-top: 7rem;
    text-align: center;
  }
  .footer-download-image{
    margin: 0;
  }
  .footer-download-image1{
    width: 100%;
  }
  .footer-container{
    background-color: $gray2;
    margin-top: -5rem; 
    padding-top: 1rem; 
    padding-bottom: 3rem;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1517px) {
  .footer-content-card{
    margin-top: 12rem; 
    text-align: center;
  }
  .footer-download-image{
    text-align: center;
  }
  .footer-download-image1{
    width: $content-width;
  }
  .footer-container{
    background-color: $gray2;
    margin-top: -10rem; 
    padding-top: 3rem; 
    padding-bottom: 3rem;
  }
}
@media only screen and (min-width: 1517px) {
  .footer-content-card{
    margin-top: 12rem; 
    text-align: center;
  }
  .footer-download-image{
    text-align: center;
  }
  .footer-download-image1{
    width: $content-width;
  }
  .footer-container{
    background-color: $gray2;
    margin-top: -10rem; 
    padding-top: 3rem; 
    padding-bottom: 3rem;
  }
}



.horizontal-footer{
  background-color: $gray3;
  height: 1px;
}
.phone-number{
  font-family: poppins-bold;
  font-size: .8rem;
}
.footer-link{
  text-decoration: none;
  font-size: .8rem;
  color: $black;
}
.footer-title{
  font-family: poppins-medium;
  font-size: 1rem;
  color: $black;
}
.footer-content{
  font-size: .8rem;
}
</style>