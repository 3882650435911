<template>
	<div class="page-wrapper">
		
		<section class="about_page">
				<div class="container">
						<div class="row">
								<div class="col-xl-6 col-lg-6">
										<div class="about_page_left">
												<div class="about_page_img">
														<img :src="about_us_data.url_image" alt="">
												</div>
										</div>
								</div>
								<div class="col-xl-6 col-lg-6">
										<div class="about_page_right">
												<div class="block-title text-left">
														<h4>{{ about_us_data.title }}</h4>
														<h2>{{ about_us_data.subtitle }}</h2>
												</div>
												<div class="about_page_right_text">
														<p class="first_text">{{ about_us_data.content }}</p>
												</div>
												<!-- <ul class="about_page_list list-unstyled">
														<li><i class="fa fa-check"></i>Invest in your simply neighborhood</li>
														<li><i class="fa fa-check"></i>Support people in free text extreme need</li>
														<li><i class="fa fa-check"></i>Largest global industrial business community</li>
												</ul> -->
										</div>
								</div>
						</div>
				</div>
		</section>
		
	</div>
</template>

<script>
	import Base from '@/utils/base';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Contact',
	data() {
		return {
			base: null,
			about_us_data: {},
		};
	},
	created() {
		this.base = new Base()
		this.get_data()
	},
	methods: {
		async get_data(){
			var response = await this.base.request(this.base.url_api + "/info/about-us")
		
			if(response != null){
				if(response.status === "success"){
					this.about_us_data = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
	},
});
</script>

<style lang="scss" scoped>
	.contact{
		padding-top: 120px;
		background-color: white;
	}
</style>
