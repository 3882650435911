<template>
	
	<section class="listing_details_top pb-0">
			<div class="container">
				<custom-navigation/>
				
					<div class="row mt-3">
							<div class="col-lg-7 col-xl-8">
									<div class="listing_details_top_left">
											<div class="listing_details_top_title">
													<span class="badge px-3 py-1 mr-2 rounded-sm bg-primary text-white" v-if="data.property_type != null">{{ data.property_type.name }}</span>
													<span class="badge px-3 py-1 rounded-sm bg-primary text-white" v-show="data.status != 'on_progress'">{{ data.status_format }}</span>
													<h3>{{ data.name }}</h3>
													<p>{{ data.address }}</p>
													<h4>Rp. {{ data.price.toLocaleString(base.locale_string) }}</h4>
													<!-- <a href="#" class="featured_btn" v-if="data.property_type != null">{{ data.property_type.name }}</a> -->
													<!-- <a href="#" class="featured_btn m-0" v-show="data.status != 'on_progress'">{{ data.status_format }}</a> -->
													<p>Property ID: <span>{{ data.id }}</span></p>
											</div>
									</div>
							</div>
							<div class="col-lg-5 col-xl-4">
									<div class="listing_details_top_right clearfix">
											<div class="listing_details_top_product_list_box w-100 m-0">
													<ul class="listing_details_top_product_list list-unstyled">
															<li>
																	<div class="icon_box">
																			<span class="icon-bed"></span>
																	</div>
																	<div class="text_box">
																			<h5>{{ data.bedroom.toLocaleString(base.locale_string) }}</h5>
																			<!-- <p>Bedrooms</p> -->
																	</div>
															</li>
															<li>
																	<div class="icon_box">
																			<span class="icon-shower"></span>
																	</div>
																	<div class="text_box">
																			<h5>{{ data.bathroom.toLocaleString(base.locale_string) }}</h5>
																			<!-- <p>Bathrooms</p> -->
																	</div>
															</li>
															<li>
																	<div class="icon_box">
																			<span class="icon-square-measument"></span>
																	</div>
																	<div class="text_box d-flex">
																			<h5>{{ data.land_size.toLocaleString(base.locale_string) }}</h5>&nbsp;
																			<p>m<sup>2</sup></p>
																	</div>
															</li>
															<li>
																	<div class="icon_box">
																			<span class="icon-home"></span>
																	</div>
																	<div class="text_box d-flex">
																			<h5>{{ data.building_size.toLocaleString(base.locale_string) }}</h5>&nbsp;
																			<p>m<sup>2</sup></p>
																	</div>
															</li>
													</ul>
											</div>
											<!-- <div class="listing_details_top_right_btn_box text-right">
												<a href="#" class="featured_btn" v-if="data.property_type != null">{{ data.property_type.name }}</a>
												<a href="#" class="featured_btn m-0" v-show="data.status != 'on_progress'">{{ data.status_format }}</a>
												<p>Property ID: <span>{{ data.id }}</span></p>
											</div> -->
									</div>
							</div>
					</div>
			</div>
	</section>
	
</template>

<script>
import Base from '@/utils/base';

import CustomNavigation from '@/layout/custom_navigation'

export default {
	components: {
		'custom-navigation': CustomNavigation,
	},
	props: ['data',],
	data(){
		return{
			base: null,
			arr: [],
			options: {
				spaceBetween: 100, 
				slidesPerView: 3, 
				autoplay: { delay: 5000 }, 
				pagination: {
					el: "#latest_properties_pagination",
					type: "bullets",
					clickable: true
				},
				navigation: {
					nextEl: ".latest_properties_next",
					prevEl: ".latest_properties_prev",
					clickable: true
				},
				breakpoints: {
					0: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					425: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					575: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					767: {
						spaceBetween: 30,
						slidesPerView: 2
					},
					991: {
						spaceBetween: 20,
						slidesPerView: 2
					},
					1289: {
						spaceBetween: 30,
						slidesPerView: 3
					},
					1440: {
						spaceBetween: 30,
						slidesPerView: 3
					},
				},
			},
		}
	},
	created(){
		this.base = new Base()
		
	},
	methods:{
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.listing_details_top_product_list{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	li{
		margin: 0 !important;
		flex-direction: column;
		.text_box{
			margin: 0 !important;
		}
	}
}
</style>
