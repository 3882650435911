<template>
	<div class="d-flex">
		<a href="#" @click="onBackClicked">{{ back_text != null ? back_text : 'Kembali' }}</a>
	</div>
</template>

<script>
import Base from '@/utils/base'

export default{
	props: ['back_text', ],
	data(){
		return{
			base: null,
		}
	},
	async created(){
		this.base = new Base()
	},
	methods: {
		onBackClicked(){
			this.base.back_page()
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 960px) {
	.nav-link{
		padding-left: .5rem !important;
		padding-right: .5rem !important;
	}
}
@media only screen and (min-width: 960px) {
	.nav-master-dropdown:hover>.nav-dropdown>.dropdown-menu{
		display: block;
	}
	.nav-link{
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}
}

.navbar-header *{
	font-family: poppins-bold;
}
.navbar-header a, .list-group a{
	text-decoration: none;
	color: $black;
}
.navbar-header button{
	border-radius: .5rem;
}
.navbar-home-normal-gray1{
	background-color: $gray1;
}
.navbar-home-normal-white{
	background-color: $white;
}
.navbar-home-gray1{
	transition: all 1s;
	background-color: $navbar-gray1;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}
.navbar-home-white{
	transition: all 1s;
	background-color: $navbar-white;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}
.navbar-home-gray4{
	transition: all 1s;
	background-color: $navbar-gray4;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}

.nav-dropdown .dropdown-toggle::after{
	display: none;
}
.nav-dropdown .dropdown-item, .nav-arrow{
	color: $black;
	font-family: poppins-medium;
	font-size: .8rem;
}
.nav-link{
	color: $black;
	font-family: poppins-medium;
}
.nav-link.active{
	color: $primary !important;
	font-family: poppins-bold;
}
.nav-dropdown .dropdown-item.active{
	background-color: $primary !important;
	font-size: .8rem;
	color: $white !important;
	font-family: poppins-bold;
}

</style>
