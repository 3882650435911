<template>
  
	<section class="latest_properties">
			<div class="container">
					<div class="block-title text-center">
							<h4>Telusuri Tawaran Menarik</h4>
							<h2>Properti Terbaru</h2>
					</div>
					<div class="row">
							<div class="col-12 col-md-6 col-xl-4" v-for="(data, index) in arr" :key="index">
								
									<PropertyItem
										:is_loading="is_loading"
										:data="data"
										:index="index"
										type="grid"
										list_type="home_property_list"/>
									
							</div>
					</div>
					
			</div>
	</section>
	
</template>

<script>
import Base from '@/utils/base';

import NoImage from '@/assets/no_image.png'
import PropertyItem from '@/layout/property_item'

export default {
  components: {
		PropertyItem,
  },
  props: [],
  data(){
    return{
      base: null,
			arr: [],
			is_loading: true,
			options: {
				spaceBetween: 100, 
				slidesPerView: 3, 
				lazyLoad: true,
				autoplay: { delay: 5000 }, 
				pagination: {
					el: "#latest_properties_pagination",
					type: "bullets",
					clickable: true
				},
				navigation: {
					nextEl: ".latest_properties_next",
					prevEl: ".latest_properties_prev",
					clickable: true
				},
				breakpoints: {
					0: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					425: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					575: {
						spaceBetween: 30,
						slidesPerView: 1
					},
					767: {
						spaceBetween: 30,
						slidesPerView: 2
					},
					991: {
						spaceBetween: 20,
						slidesPerView: 2
					},
					1289: {
						spaceBetween: 30,
						slidesPerView: 3
					},
					1440: {
						spaceBetween: 30,
						slidesPerView: 3
					},
				},
			},
    }
  },
  created(){
    this.base = new Base()
		
		this.get_data()
  },
  methods:{
		async get_data(){
			this.is_loading = true
			var response = await this.base.request(this.base.url_api + "/property?is_publish=1&num_data=6")
		
			if(response != null){
				if(response.status === "success"){
					response.data.url_image = response.data.file_name != null ? this.base.host + '/media/property?file_name=' + response.data.file_name : NoImage
					for(let data of response.data){
						data.url_image = data.file_name != null ? this.base.host + '/media/property?file_name=' + data.file_name : NoImage
					}
					this.arr = response.data
					
					var context = this
					setTimeout(() => {
						context.is_loading = false
					}, 3000)
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-right: .5rem;
  }
  .article-second{
    padding-left: .5rem;
  }
}
@media only screen and (min-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-left: .5rem;
  }
  .article-second{
    padding: 0 .5rem;
  }
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
  transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
  transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
  transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
</style>
