<template>
  <div class="">
		
		<Banner/>
		<!-- <WhyChoose/> -->
		<ListProperty/>
		<!-- <HowWork/> -->
		<!-- <Blog/> -->
		<!-- <Brand/> -->
    
  </div>
</template>

<script>
import Base from '@/utils/base';

import Banner from '@/pages/home/component/banner.vue'
// import WhyChoose from '@/pages/home/component/why_choose.vue'
import ListProperty from '@/pages/home/component/list_property.vue'
// import HowWork from '@/pages/home/component/how_work.vue'
// import Blog from '@/pages/home/component/blog.vue'
// import Brand from '@/pages/home/component/brand.vue'

export default {
  components: {
    'Banner': Banner,
    // 'WhyChoose': WhyChoose,
    'ListProperty': ListProperty,
    // 'HowWork': HowWork,
    // 'Blog': Blog,
		// 'Brand': Brand,
  },
  data(){
    return{
      base: null,
    }
  },
  created(){
    this.base = new Base()
		this.base.save_current_page('Home', true)
  },
  methods: {
  },
}
</script>

<style lang="scss">
.vertical{
  background-color: $gray2;
  width: 1px;
  height: 100%;
}
.slick-prev:before, .slick-next:before {
  background-color: $black !important;
}
.slick-dots {
  bottom: -5rem;
}
.slick-dots button:before {
  color: $gray8 !important; /* color of dots */
  font-size: .8rem !important;
  opacity: 1 !important;
}
.slick-dots .slick-active button:before {
  color: $primary !important;
  font-size: .8rem !important;
  opacity: 1 !important;
}
</style>
