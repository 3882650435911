<template>
	<div class="container py-5 w-100">
		<div class="mb-5">
			<h4>List Properti</h4>
		</div>

		<div class="row">
			<div class="col-12 col-lg-3">
				<form>
					<div class="border rounded-lg p-3 sticky-lg-top">
						<div class="form-group">
							<label for="search">Pencarian</label>
							<input type="text" class="form-control" name="search" id="search" aria-describedby="search"
								placeholder="Masukkan kata kunci" v-model="search" />
						</div>

						<div class="form-group">
							<label for="location">Pilih Lokasi</label>
							<Select2 v-model="city" :options="arr_city" :settings="arr_setting" id="location" />
						</div>
						
						<div class="form-group">
							<label for="property_type">Pilih Tipe Properti</label>
							<Select2 v-model="property_type" :options="arr_property_type" :settings="arr_setting_property_type" value="Semua Tipe Properti" id="property_type" />
						</div>

						<div class="form-group">
							<label>Luas Tanah</label>
							<div class="d-flex flex-row justify-content-around" style="gap: 0.5rem">
								<input type="text" class="form-control" name="size-min" id="size-min" aria-describedby="size-min"
									v-model="min_land_size" placeholder="min" />
								<input type="text" class="form-control" name="size-max" id="size-max" aria-describedby="size-max"
									v-model="max_land_size" placeholder="max" />
							</div>
						</div>

						<div class="form-group">
							<label>Luas Bangunan</label>
							<div class="d-flex flex-row justify-content-around" style="gap: 0.5rem">
								<input type="text" class="form-control" name="size-min" id="size-min" aria-describedby="size-min"
									v-model="min_building_size" placeholder="min" />
								<input type="text" class="form-control" name="size-max" id="size-max" aria-describedby="size-max"
									v-model="max_building_size" placeholder="max" />
							</div>
						</div>

						<div class="form-group">
							<label for="bedroom">Kamar Tidur</label>
							<select class="form-control" name="bedroom" id="bedroom" v-model="bedroom">
								<option>1</option>
								<option>2</option>
								<option>more</option>
							</select>
						</div>

						<div class="form-group">
							<label for="bathroom">Kamar Mandi</label>
							<select class="form-control" name="bathroom" id="bathroom" v-model="bathroom">
								<option>1</option>
								<option>2</option>
								<option>more</option>
							</select>
						</div>

						<div class="form-group">
							<label>Rentang Harga</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="my-addon">Rp</span>
								</div>
								<input type="text" class="form-control" name="price-min" id="price-min" aria-describedby="price-min"
									v-model="min_price" placeholder="min" />
							</div>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="my-addon">Rp</span>
								</div>
								<input type="text" class="form-control" name="price-max" id="price-max" aria-describedby="price-max"
									v-model="max_price" placeholder="max" />
							</div>
						</div>
						
						<div class="form-group">
							<button type="button" @click="onResetClicked"
							class="thm-btn w-100">Reset Filter</button>
						</div>
					</div>
				</form>
			</div>

			<div class="col-12 col-lg-9">

				<div class="row">
					<div class="col-xl-12">
						<div class="filter">
							<div class="filter_inner_content">
								<div class="left">
									<div class="left_icon">
										<a href="#!" :class="'icon-grid ' + (type == 'grid' ? 'active' : '')" @click="onGridClicked"></a>
										<a href="#!" :class="'list-icon icon-list ' + (type == 'list' ? 'active' : '')"
											@click="onListClicked"></a>
									</div>
									<div class="left_text">
										<h4>
											{{ recordsFiltered.toLocaleString(base.locale_string) }} Properti ditemukan
										</h4>
									</div>
								</div>
								<div class="right">
									<div class="shorting">
										<select class="form-control" v-model="sort" data-width="100%">
											<option value="name_asc">A - Z</option>
											<option value="name_desc">Z - A</option>
											<option value="price_asc">Harga (Rendah ke Tinggi)</option>
											<option value="price_desc">Harga (Tinggi ke Rendah)</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="listings_page_content">
					<div v-if="arr.length > 0">

						<div class="row">
							<div :class="{ 'col-12 col-md-6': type == 'grid', 'col-12': type == 'list' }" v-for="(data, index) in arr"
								:key="index">
								
								<PropertyItem
									:is_loading="is_loading"
									:data="data"
									:index="index"
									:type="type"
									@onMakeOfferClicked="onMakeOfferClicked"
									@onOfferListClicked="onOfferListClicked"
									list_type="property_list"/>
								
							</div>
						</div>

						<div class="d-flex align-items-center justify-content-center">
							<b-pagination
								v-model="page"
								:total-rows="recordsFiltered"
								:per-page="num_data"
								aria-controls="my-table"/>
						</div>

						<!-- <div class="row mb-5" v-show="page < total_page">
							<div class="col-xl-12">
								<div class="listings_page_content_load_more_btn">
									<a href="#" class="thm-btn" @click="onLoadClicked">Load More</a>
								</div>
							</div>
						</div> -->

					</div>

					<div v-else>
						<h4 class="text-center">Data Tidak Ditemukan</h4>
					</div>
				</div>
			</div>
		</div>

		<modal-offer :data="selected_property" />
		<modal-list-offer :data="selected_property" />

	</div>
</template>

<script>
import Base from "@/utils/base";

import NoImage from "@/assets/no_image.png";
import ModalOffer from '@/pages/property/component/modal_offer'
import ModalListOffer from '@/pages/property/component/modal_list_offer'
import PropertyItem from '@/layout/property_item'

var base = new Base()
export default {
	components: { ModalOffer, ModalListOffer, PropertyItem, },
	props: ["isFinished"],
	data() {
		return {
			base: null,
			arr: [],
			user_data: {},
			selected_property: {},
			search: "",
			type: "grid",
			is_loading: true,
			recordsFiltered: 0,
			bedroom: "0",
			bathroom: "0",
			min_land_size: "0",
			max_land_size: "0",
			min_building_size: "0",
			max_building_size: "0",
			min_price: "0",
			max_price: "0",
			city: "all",
			page: 1,
			total_page: 3,
			num_data: 6,
			arr_city: [{ id: "all", text: "Semua Lokasi" }],
			property_type: 'all',
			arr_property_type: [{ id: 'all', text: 'Semua Tipe Properti' }],
			sort: "name_asc",
			timeout: null,
			firstTime: true,
			arr_setting: {
				ajax: {
					url: base.url_api + "/city",
					headers: {
						Accept: "application/json",
					},
					processResults: function (data) {
						var arr = [{ id: "all", text: "Semua Lokasi" }];
						for (let item of data.data) {
							item.text = item.name;
							arr.push(item);
						}
			
						return {
							results: arr,
						};
					},
				},
			},
			arr_setting_property_type: {
				ajax: {
					url: base.url_api + "/property/type",
					headers: {
						Accept: "application/json",
					},
					processResults: function (data) {
						var arr = [{ id: "all", text: "Semua Tipe Properti" }];
						for (let item of data.data) {
							item.text = item.name;
							arr.push(item);
						}
			
						return {
							results: arr,
						};
					},
				},
			},
		};
	},
	watch: {
		search(){
			this.onFilterChanged()
		},
		city() {
			this.get_data();
		},
		property_type() {
			this.get_data()
		},
		page() {
			this.get_data()
		},
		bedroom(val) {
			this.bedroom = this.base.to_currency_format(val);
			this.onFilterChanged();
		},
		bathroom(val) {
			this.bathroom = this.base.to_currency_format(val);
			this.onFilterChanged();
		},
		min_land_size(val) {
			if (this.base.str_to_double(val) > this.base.str_to_double(this.max_land_size))
				this.max_land_size = this.base.to_currency_format(val);
			this.min_land_size = this.base.to_currency_format(val);

			this.onFilterChanged();
		},
		max_land_size(val) {
			if (this.base.str_to_double(this.min_land_size) > this.base.str_to_double(val))
				this.min_land_size = this.base.to_currency_format(val);
			this.max_land_size = this.base.to_currency_format(val);

			this.onFilterChanged();
		},
		min_building_size(val) {
			if (this.base.str_to_double(val) > this.base.str_to_double(this.max_building_size))
				this.max_building_size = this.base.to_currency_format(val);
			this.min_building_size = this.base.to_currency_format(val);
		
			this.onFilterChanged();
		},
		max_building_size(val) {
			if (this.base.str_to_double(this.min_building_size) > this.base.str_to_double(val))
				this.min_building_size = this.base.to_currency_format(val);
			this.max_building_size = this.base.to_currency_format(val);
		
			this.onFilterChanged();
		},
		min_price(val) {
			if (this.base.str_to_double(val) > this.base.str_to_double(this.max_price))
				this.max_price = this.base.to_currency_format(val);
			this.min_price = this.base.to_currency_format(val);

			this.onFilterChanged();
		},
		max_price(val) {
			if (this.base.str_to_double(this.min_price) > this.base.str_to_double(val))
				this.min_price = this.base.to_currency_format(val);
			this.max_price = this.base.to_currency_format(val);

			this.onFilterChanged();
		},
		sort(val) {
			this.arr = []
			var page = this.page
			if (page > 1)
				this.page = 1
			else
				this.get_data();
		},
		arr(val) {
			if (val.length > 0) {
				setTimeout(() => {
					this.base.init_list_property();
				}, 1000);
			}
		},
		// isFinished(val){
		// 	if(val)
		// 		this.get_data()
		// },
	},
	async created() {
		this.base = new Base();
		this.base.save_current_page("Property", true);

		var token = await localStorage.getItem('token')
		if (token != null)
			await this.get_auth()

		if(this.$route.query.search != null)
			this.search = this.$route.query.search
		else
			this.get_data();
	},
	methods: {
		onResetClicked(){
			this.search = ''
			this.city = 'all'
			this.property_type = 'all'
			this.bedroom = '0'
			this.bathroom = '0'
			this.min_land_size = '0'
			this.max_land_size = '0'
			this.min_building_size = '0'
			this.max_building_size = '0'
			this.min_price = '0'
			this.max_price = '0'
		},
		onMakeOfferClicked(index) {
			if (this.user_data.id != null) {
				this.selected_property = this.arr[index]
				window.$('#modal_offer').modal('show')
			}
			else
				window.$('#login-modal').modal('show')
		},
		onOfferListClicked(index) {
			this.selected_property = this.arr[index]
			window.$('#modal_list_offer').modal('show')
		},
		onLoadClicked() {
			this.page++
		},
		onFilterChanged() {
			if (this.timeout != null) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.arr = []
				var page = this.page
				if (page > 1)
					this.page = 1
				else
					this.get_data();
			}, 1500);
		},
		onGridClicked() {
			this.type = "grid";
		},
		onListClicked() {
			this.type = "list";
		},
		async get_auth() {
			var response = await this.base.request(this.base.url_api + "/auth/profile");

			if (response != null) {
				if (response.status === "success") {
					this.user_data = response.data;
				} else this.base.show_error(response.message);
			} else this.base.show_error(this.$t("server_error"));
		},
		async get_data() {
			if (this.isFinished) window.$("#please_wait_modal").modal("show");
			this.is_loading = true;

			var sort_explode = this.sort.split("_");
			var arr_sort = [{ name: sort_explode[0], sort: sort_explode[1] }];

			var response = await this.base.request(
				this.base.url_api +
				"/property" + (this.user_data.id != null ? '/auth' : '') + "?is_publish=1&bedroom=" + this.base.str_to_double(this.bedroom) +
				"&bathroom=" + this.base.str_to_double(this.bathroom) +
				"&min_land_size=" + this.base.str_to_double(this.min_land_size) +
				"&max_land_size=" + this.base.str_to_double(this.max_land_size) +
				"&min_building_size=" + this.base.str_to_double(this.min_building_size) +
				"&max_building_size=" + this.base.str_to_double(this.max_building_size) +
				"&city_id=" + this.city +
				"&property_type_id=" + this.property_type + 
				"&page=" + this.page +
				"&search=" + this.search +
				"&num_data=" + this.num_data +
				"&sort=" + JSON.stringify(arr_sort)
			);
			if (this.isFinished)
				setTimeout(() => {
					window.$("#please_wait_modal").modal("hide");
				}, 500);

			if (response != null) {
				if (response.status === "success") {
					var arr = []
					for (let data of response.data) {
						data.url_image =
							data.file_name != null
								? this.base.host + "/media/property?file_name=" + data.file_name
								: NoImage;
						arr.push(data)
					}
					this.arr = arr
					this.recordsFiltered = response.recordsFiltered;
					this.total_page = response.total_page

					var context = this;
					setTimeout(() => {
						context.is_loading = false;
					}, 3000);

					if (!this.firstTime) {
						setTimeout(() => {
							context.base.reloadTheme()
						}, 1000)
					}
					else
						this.firstTime = false

				} else this.base.show_error(response.message);
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
	},
};
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first {
		padding-right: 0.5rem;
	}

	.article-last {
		padding-right: 0.5rem;
	}

	.article-second {
		padding-left: 0.5rem;
	}
}

@media only screen and (min-width: 720px) {
	.article-first {
		padding-right: 0.5rem;
	}

	.article-last {
		padding-left: 0.5rem;
	}

	.article-second {
		padding: 0 0.5rem;
	}
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
	transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
	transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
	transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}

.property-item-list {
	display: grid;
	grid-template-columns: 125px 1fr;

	@media (min-width: 768px) {
		grid-template-columns: 200px 1fr;
	}

	grid-template-rows: 1fr;
	grid-column-gap: 1rem;
	grid-row-gap: 0px;
	padding: 0;
}

.item-list-owl-image {
	width: 100%;
	aspect-ratio: 1 / 1 !important;
	object-fit: cover;
	object-position: center;
	// @media (min-width: 960px) {
	// 	aspect-ratio: 4 / 3 !important;
	// }
}

.item-grid-owl-image {
	width: 100%;
	aspect-ratio: 16 / 9 !important;
	object-fit: cover;
	object-position: center;

	@media (min-width: 960px) {
		aspect-ratio: 4 / 3 !important;
	}
}

.latest_properties_top_content {
	@media (max-width: 768px) {
		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;

		h4 {
			font-size: 14px;
		}

		h3 {
			font-size: 16px;
			line-height: 1.5;
		}
	}
}

.latest_properties_bottom_content {
	.list-unstyled {
		gap: 1rem;

		li {
			margin: 0;
			display: flex;
			align-items: center;

			span {
				padding-right: 0.25rem;
			}

			@media (max-width: 768px) {
				font-size: 12px;
			}
		}

		// &.list_mode {
			display: grid;
			grid-template-columns: 1fr 1fr;
			@media(min-width: 768px){
				width: 100%;
				grid-template-columns: 1fr 1fr
			}
			gap: .5rem;
		}
	// }
}
</style>
