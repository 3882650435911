<template>

	<div class="modal fade" id="modal_offer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Buat Penawaran</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label for="exampleInputEmail1">Harga</label>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">Rp.</span>
							</div>
							<input id="offeredPrice" type="text" class="form-control" v-model="price" placeholder="0" aria-label="Username"
								aria-describedby="basic-addon1" required>
							<div class="invalid-feedback">
								Harga telah ditawarkan oleh pengguna lain.
							</div>
						</div>
					</div>

					<div class="form-group">
						<label for="exampleInputEmail1">Pesan (Optional)</label>
						<textarea class="form-control" v-model="message" placeholder="" aria-label="Username"
							aria-describedby="basic-addon1"></textarea>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
					<button type="button" class="btn btn-primary" @click="submit">Ajukan Penawaran</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import Base from '@/utils/base';

export default {
	components: {
	},
	props: ['data',],
	data() {
		return {
			base: null,
			price: '0',
			message: '',
		}
	},
	watch: {
		price(val) {
			this.price = this.base.to_currency_format(val, 1000000000000)
		}
	},
	created() {
		this.base = new Base()

	},
	methods: {
		async submit() {
			if (this.price == '')
				this.base.show_error('Price is Empty')
			else {
				window.$('#please_wait_modal').modal('show')
				var response = await this.base.request(this.base.url_api + '/property/offer', 'post', {
					price: this.price,
					property_id: this.data.id,
					message: this.message,
				})
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)


				if (response != null) {
					if (response.status == 'success') {
						window.location.reload()
					}
					else{
						// this.base.show_error(response.message)
						window.$('#offeredPrice').addClass('is-invalid');
					}
				}
				else
					this.base.show_error("Server Error")
			}
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first {
		padding-right: .5rem;
	}

	.article-last {
		padding-right: .5rem;
	}

	.article-second {
		padding-left: .5rem;
	}
}

@media only screen and (min-width: 720px) {
	.article-first {
		padding-right: .5rem;
	}

	.article-last {
		padding-left: .5rem;
	}

	.article-second {
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active,
.home-article-title1-leave-active {
	transition: all 2s;
}

.home-article-title1-leave-to,
.home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}

.home-article-title2-enter-active,
.home-article-title2-leave-active {
	transition: all 2s;
}

.home-article-title2-leave-to,
.home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.home-article-content-enter-active,
.home-article-content-leave-active {
	transition: all 2s;
}

.home-article-content-leave-to,
.home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
