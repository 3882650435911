<template>
	
	<section class="listing_details py-3">
			<div class="container">
					<div class="row mt-md-5">
							<div class="col-12 col-xl-8 col-lg-7">
									<div class="listing_details_left">
											<div class="listing_details_text pb-3 mb-3">
													<p class="text_1 pb-3">{{ data.description }}</p>
											</div>
											
											<!-- <div class="review_two_box">
													<h3 class="review_two_box__title">Offer Property</h3>
													
													<div>
														<div class="review_two_box__single" v-for="(offer_property, index) in data.offer" :key="index">
																<div class="review_two_box__image">
																		<img :src="offer_property.user.url_image" alt="">
																</div>
																<div class="review_two_box__content">
																		<div class="review_two_box_title">
																				<div class="reviewer_name">
																						<h3>{{ offer_property.user.name }}</h3>
																				</div>
																		</div>
																		<p>Rp. {{ offer_property.price.toLocaleString(base.locale_string) }}</p>
																</div>
														</div>
													</div>
													
											</div> -->
											
									</div>
							</div>
							<div class="col-12 col-xl-4 col-lg-5">
								
								<div class="listing_details_sidebar mt-3 mt-md-0">
									<div class="listing_details_sidebar_agent">
										
										<div class="listing_details_sidebar_agent_title" v-show="data.status == 'sold'">
											<h3>Terjual</h3>
											<h5>{{ data.sold_user != null ? data.sold_user.name : data.sold_user_phone }}</h5>
											<p>Rp. {{ data.sold_price.toLocaleString(base.locale_string) }}</p>
										</div>

										<div class="listing_details_sidebar_agent_title" v-if="user_data.id != null && data.own_offer != null && data.status == 'on_progress'">
											<!-- <h5>Harga Penawaran: Rp. {{ data.own_offer.price.toLocaleString(base.locale_string) }} @ {{ data.own_offer.created_at_moment.format('DD/MM/YYYY') }}</h5> -->

											<small>{{ data.own_offer.created_at_moment.format('DD/MM/YYYY') }}</small>
											<h3>Rp. {{ data.own_offer.price.toLocaleString(base.locale_string) }}</h3>
											<small>Penawaran Terakhir Anda</small>
										</div>
										
										<button type="button" @click="onMakeOfferClicked" v-if="data.status == 'on_progress'" class="thm-btn w-100"
											:class="{'bg-primary': user_data.id != null && data.status == 'on_progress'}">Ditawar aja</button>
										
										
									</div>
								</div>
									
							</div>
					</div>
			</div>
	</section>
	
</template>

<script>


import Base from '@/utils/base';

export default {
	components: {
	},
	props: ['data', 'user_data',],
	data(){
		return{
			base: null,
			arr: [],
		}
	},
	created(){
		this.base = new Base()
	},
	methods:{
		
		onMakeOfferClicked(){
			if(this.user_data.id != null){
				window.$('#modal_offer').modal('show')
			}
			else{
				window.$('#login-modal').modal('show')
			}
		},
		onChatOfferClicked(){
			window.location.href = '/property/chat?id=' + this.data.own_offer.id
		},
		
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.text_1{
	line-height: 2 !important;
}
</style>
