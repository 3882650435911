<template>
	
	<div class="modal fade" id="modal_list_offer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Daftar Penawaran Anda</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th>Tanggal Pengajuan</th>
									<th>Harga</th>
									<th>Status</th>
									<th>Catatan</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(offer_property, index) in arr" :key="index">
									<th>{{ offer_property.dateMoment.format('DD/MM/YYYY') }}</th>
									<th>Rp. {{ offer_property.price.toLocaleString(base.locale_string) }}</th>
									<th v-html="offer_property.status_format"></th>
									<th>{{ offer_property.response_message }}</th>
								</tr>
							</tbody>
						</table>
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import Base from '@/utils/base';

export default {
	components: {
	},
	props: ['data', 'user_data', ],
	data(){
		return{
			base: null,
			price: '0',
			message: '',
			arr: [],
		}
	},
	watch:{
		price(val){
			this.price = this.base.to_currency_format(val, 1000000000000)
		},
		data(val){
			if(val.id != null)
				this.get_data()
		},
	},
	created(){
		this.base = new Base()
		
	},
	methods:{
		async get_data(){
			var token = await window.localStorage.getItem('token')
			var response = await this.base.request(this.base.url_api + "/property/offer" + (token != null ? '/auth' : '') + "?property_id=" + this.data.id)
		
			if(response != null){
				if(response.status === "success"){
					for(let data of response.data){
						data.dateMoment = moment(data.created_at)
					}
					
					this.arr = response.data
				}
				else
					this.base.show_error(response.message)
			}
			// else
			//   this.base.show_error(this.$t('server_error'))
		},
		async submit() {
			if(this.price == '')
				this.base.show_error('Price is Empty')
			else{
				window.$('#please_wait_modal').modal('show')
				var response = await this.base.request(this.base.url_api + '/property/offer', 'post', {
					price: this.price,
					property_id: this.data.id,
					message: this.message,
				})
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)
				
				
				if (response != null) {
					if (response.status == 'success') {
						window.location.reload()
					}
					else
						this.base.show_error(response.message)
				}
				else
					this.base.show_error("Server Error")
			}
		},
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-right: .5rem;
	}
	.article-second{
		padding-left: .5rem;
	}
}
@media only screen and (min-width: 720px) {
	.article-first{
		padding-right: .5rem;
	}
	.article-last{
		padding-left: .5rem;
	}
	.article-second{
		padding: 0 .5rem;
	}
}

.home-article-title1-enter-active, .home-article-title1-leave-active{
	transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
	transform: translateY(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
	transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
	transform: translateY(-10rem);
	opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
	transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
	transform: translateX(-10rem);
	// margin-bottom: 5rem !important;
	opacity: 0;
}
</style>
